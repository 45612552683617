import { useQuery } from "@tanstack/react-query";
import { get } from "../../../lib";
import { ISupplierInfoDetails } from "./dtos";
import { getGetSupplierInfo } from "../../../constants/reactQueryKey";

export const useGetSupplierInfo = (supplierId: string) => {
    return useQuery([getGetSupplierInfo, supplierId], async () => {
        return await get<ISupplierInfoDetails>(
            `/bookings/${supplierId}/supplierInfo`
        );
    });
};
