import { Typography } from "@mui/material";
import "../../assets/styles/components/BlurredTypograhy.scss";

interface Props {
    isNumber?: boolean;
    length?: number;
    className?: string;
}

/**
 *
 * BlurredTypograhy is a React component which is responsible for displaying a blurred or obscured string of characters, intended to mask sensitive information.
 * It generates a random string of alphabetic or numeric characters based on the provided properties, and displays this string
 * with a visual style that indicates the content is secured or hidden. The component can be used wherever sensitive information
 * needs to be obfuscated in the user interface to maintain privacy or security.
 *
 */
function BlurredTypograhy({ isNumber, length, className }: Readonly<Props>) {
    // Function to generate a random string of specified length.
    // The string can be alphabetic, numeric, or alphanumeric based on the `isNumber` prop.
    function generateRandomString(): string {
        // Calculate the length of the string to generate.
        // If length is not provided, default to a random length between 10 to 24 characters.
        const lengthValue = length || 10 + Math.floor(Math.random() * 15);
        let result = "";
        const alphabets =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        const numbers = "0123456789";
        const characters = isNumber ? numbers : alphabets + numbers;
        const charactersLength = characters.length;

        for (let i = 0; i < lengthValue; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }
        return result;
    }

    return (
        <div className="flex-wrap align-center">
            <Typography className={`blurryText ${className}`}>
                {generateRandomString()}
            </Typography>
            <div className="icon icon-lock"></div>
        </div>
    );
}

export default BlurredTypograhy;
