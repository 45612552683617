import { ZipCodeAreaType } from "../../types";

export function getUrlTypeFromAreaType(areaType: ZipCodeAreaType): string {
    switch (areaType) {
        case ZipCodeAreaType.State:
            return "states";
        case ZipCodeAreaType.City:
            return "cities";
        case ZipCodeAreaType.District:
            return "districts";
        default:
            return "";
    }
}

export function getAreaTypeNameInGermanFromAreaType(
    areaType: ZipCodeAreaType
): string {
    switch (areaType) {
        case ZipCodeAreaType.State:
            return "Bundesland";
        case ZipCodeAreaType.City:
            return "Stadt";
        case ZipCodeAreaType.District:
            return "Stadtteil";
        default:
            return "";
    }
}
