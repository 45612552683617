/**
 * WaitingListLeadStateRejected is a React component which is part of a system handling waiting list leads
 * and is specifically used to display a notification when
 * a lead has been rejected by the supplier.
 *
 */
function WaitingListLeadStateRejected() {
    return (
        <div className="waiting-list-lead-rejected-container">
            <div className="flex-nowrap align-center mb-20">
                <div className="icon icon-close-orange"></div>
                <h3 className="text-orange-peel">
                    Sie haben die Anfrage abgelehnt.
                </h3>
            </div>
        </div>
    );
}

export default WaitingListLeadStateRejected;
