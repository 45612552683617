import {
    Select,
    MenuItem,
    FormControl,
    Checkbox,
    ListItemText
} from "@mui/material";
import {
    IProductCriteriaOption,
    IProductCriteriaOptionConfiguration
} from "../types/interfaces";
import { useState, useMemo } from "react";

interface Props {
    criteriaId: string;
    optionConfiguration: IProductCriteriaOptionConfiguration;
    onClick: (criteriaId: string, options: IProductCriteriaOption[]) => void;
}

/**
 * MultiSelectDropdown is a React component that presents a dropdown menu allowing the selection of multiple
 * options within a product criterion. It handles complex selection logic,
 * including the potential for 'reverse exclude' behavior. It manages the
 * selection state of each option and provides a mechanism to report changes
 * to a parent component for potential calculations.
 */
function MultiSelectDropdown({
    criteriaId,
    optionConfiguration,
    onClick
}: Readonly<Props>) {
    const reverseExcludeOrder = (
        optionConfiguration: IProductCriteriaOptionConfiguration
    ) => {
        return optionConfiguration.reverseExcludeOrder;
    };

    const options = useMemo(() => optionConfiguration.options, []);
    const [selectedOptions, setSelectedOptions] = useState<
        IProductCriteriaOption[]
    >(
        optionConfiguration.options.filter(
            (x) => x.isSelected === !reverseExcludeOrder(optionConfiguration)
        )
    );

    const handleOptionChange = (
        selectedListUIOptionBeforeSet: IProductCriteriaOption[]
    ) => {
        const reverseExclude = reverseExcludeOrder(optionConfiguration);
        const modifiedOptions = options.map((item) => ({
            ...item,
            isSelected: reverseExclude
                ? !selectedListUIOptionBeforeSet.some(
                      (selectedItem) =>
                          selectedItem["optionId"] === item["optionId"]
                  )
                : selectedListUIOptionBeforeSet.some(
                      (selectedItem) =>
                          selectedItem["optionId"] === item["optionId"]
                  )
        }));
        onClick(criteriaId, modifiedOptions);
    };

    return (
        <FormControl fullWidth>
            <Select
                multiple
                value={selectedOptions.map((option) => option.optionId)}
                onChange={(event) => {
                    const selectedValues = event.target.value as string[];
                    const selectedUiOptions = options.filter((option) =>
                        selectedValues.includes(option.optionId)
                    );
                    handleOptionChange(selectedUiOptions);
                    setSelectedOptions(selectedUiOptions);
                }}
                renderValue={(selected) => {
                    const selectedNames = optionConfiguration.options
                        .filter((option) => selected.includes(option.optionId))
                        .map((option) => option.optionsName);
                    return selectedNames.join(", ");
                }}
            >
                {options.map((option) => (
                    <MenuItem
                        className="multi-select-dropdown-item"
                        key={option.optionId}
                        value={option.optionId}
                        hidden={option.isHidden}
                    >
                        <Checkbox
                            checked={selectedOptions.some(
                                (selectedOption) =>
                                    selectedOption.optionId === option.optionId
                            )}
                        />
                        <ListItemText primary={option.optionsName} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MultiSelectDropdown;
