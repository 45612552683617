import { Outlet } from "react-router-dom";
import "../../assets/styles/components/BodyContainer.scss";

interface Props {
    children: React.ReactNode;
    hideOutlet?: boolean;
}


/**
 *
 * BodyContainer is a React component that serves as a layout wrapper for the main content areas of a web application. It is designed to structure
 * the page content by encapsulating passed children components and an `Outlet` component from React Router, which renders
 * the current route's component. This setup facilitates a consistent layout structure across different pages of the application.
 *
 */
function BodyContainer({ children, hideOutlet }: Readonly<Props>) {
    return (
        <div className="body-container">
            {children}
            <div className="main-container" hidden={hideOutlet}>
                <Outlet />
            </div>
        </div>
    );
}

export default BodyContainer;
