import { useState, useMemo } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { IGeneralCriteria } from "../types/interfaces";
import { DayOfWeek } from "../../../types/enums";
import { getDayNameInGerman } from "../../../utils";

interface Props extends IGeneralCriteria {
    onChangeGeneralCriteria: (generalCriteria: IGeneralCriteria) => void;
}

/**
 * GeneralCriteria is a React component that manages user preferences related to contact details
 * and the days on which inquiries or bookings are allowed. It provides a user-friendly
 * interface for configuring these settings. The callback function onChangeGeneralCriteria
 * is called whenever the user updates the criteria to update potential calculations.
 *
 */
const GeneralCriteria = ({
    isRequestEmailRequired,
    isRequestValidAddressRequired,
    noCrossSelling,
    weekDayTimes,
    onChangeGeneralCriteria
}: Props) => {
    const contactDetails = useMemo(() => {
        return {
            isRequestEmailRequired,
            isRequestValidAddressRequired,
            noCrossSelling
        };
    }, [isRequestEmailRequired, isRequestValidAddressRequired, noCrossSelling]);

    const [weekDays, setWeekDays] = useState(
        [...weekDayTimes].sort((a, b) => {
            if (a.dayOfWeek === DayOfWeek.Sunday) return 1;
            if (b.dayOfWeek === DayOfWeek.Sunday) return -1;

            return a.dayOfWeek - b.dayOfWeek;
        })
    );

    const handleWeekdayChange = (weekDay: DayOfWeek, checked: boolean) => {
        const newWeekDays = weekDays.map((item) =>
            item.dayOfWeek === weekDay
                ? { ...item, isContactOnThisDayAllowed: checked }
                : item
        );

        setWeekDays(newWeekDays);
        updateGeneralCriteria(contactDetails, newWeekDays);
    };

    const updateGeneralCriteria = (
        updatedContactDetails: typeof contactDetails,
        updatedWeekDays: typeof weekDays
    ) => {
        onChangeGeneralCriteria({
            ...updatedContactDetails,
            weekDayTimes: updatedWeekDays
        });
    };

    return (
        <div className="criteria-contaner">
            <div className="criteria-header">
                <h3>Anfragen</h3>
            </div>
            <div className="criteria-body">
                <p className="highlight-blue">Zeitliche Anfragenstellung</p>
                <FormGroup>
                    {weekDays.map((weekDay) => (
                        <FormControlLabel
                            key={weekDay.dayOfWeek}
                            control={
                                <Checkbox
                                    checked={weekDay.isContactOnThisDayAllowed}
                                    onChange={(e) =>
                                        handleWeekdayChange(
                                            weekDay.dayOfWeek,
                                            e.target.checked
                                        )
                                    }
                                />
                            }
                            label={getDayNameInGerman(weekDay.dayOfWeek)}
                        />
                    ))}
                </FormGroup>
            </div>
        </div>
    );
};

export default GeneralCriteria;
