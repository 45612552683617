export enum ViewType {
    MultiSelectDropdown,
    MultiSelectCheckBox,
    SingleSelectRadioButton,
    Slider
}

export enum CriteriaInputType {
    Options = 0,
    Slider = 1
}
