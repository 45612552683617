import { useNavigate, useParams } from "react-router-dom";
import { Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { calculateTotalNumberOfMatchRequest } from "../utils/bookingDetailsContainer";
import {
    convertProductCriteriaDataToPropsData,
    convertProductCriteriaMatchingInfoToPropsData,
    convertToGeneralCriteriaPropsData,
    convertBookingPotentialsToGeneralCriteriaPotentialPropsData,
    convertBookingPotentialsToPricePotentialPropsData,
    convertBookingPotentialsToZipCodePotentialPropsData
} from "../utils/bookingDetailsContainer/mapper";
import { CriteriaGroup } from "../../criteria";
import { CustomTabPanel } from "../../../components/ui";
import BookingPriceOverview from "./BookingPriceOverview";
import { BookingDetailsContainerTab } from "../types/enums";
import BookingStickyHeader from "./BookingStickyHeader";
import { useGetBookingInfo } from "../services/bookingService";
import { IBookingDetail } from "../services/dtos";
import useBookingTabRoute from "../hooks/bookingDetailsContainer/useBookingTabRoute";
import useBookingSticky from "../hooks/bookingDetailsContainer/useBookingSticky";
import "../../../assets/styles/booking/BookingDetailsContainer.scss";
import BookingZipCodeOverview from "./BookingZipCodeOverview";
import { isValidUUID } from "../../../utils";
import BookingChangesHint from "./BookingChangesHint";

export interface IProductPotentialState {
    generalCriteriaPotentialPercentage: number;
    productCriteriaPotentialPercentage: number;
    weekdayPotentialPercentage: number;
    zipCodePotentialPercentage: number;
    totalNumberOfSoldRequest: number;
    pricePotentialPercentage: number;
}
/**
 * BookingDetailsContainer is a React component designed to manage and display the interface
 * for booking details in a dynamic and interactive format.
 * It integrates several key functionalities, including:
 *
 * - Navigation between different aspects of a booking via tabs (criteria, budget optimization, and geographical targeting).
 * - Handling of state changes related to booking data that affect visual components and interactions, such as potential calculations and criteria matching.
 * - Routing based on booking and supplier validity to handle error states or invalid data.
 * - Integration with various hooks for fetching data, managing URL parameters, and maintaining UI state.
 *
 * The component serves as a central hub for the booking management section of the application,
 * providing tools and information necessary for users to optimize and understand their booking details.
 *
 */
function BookingDetailsContainer() {
    const { bookingId } = useParams() as { bookingId: string };
    const { supplierId } = useParams() as { supplierId: string };
    const { isSticky } = useBookingSticky();
    const navigate = useNavigate();
    const { currentBookingTab, tabHandleChange } = useBookingTabRoute();
    const [soldRequest, setSoldRequest] = useState<IProductPotentialState>();
    const {
        data: initializeBookingInfo,
        isSuccess,
        isError
    } = useGetBookingInfo(bookingId);

    useEffect(() => {
        if (isSuccess) {
            intializeSoldRequestData(initializeBookingInfo);
        } else {
            setSoldRequest(undefined);
        }
        if (isError) {
            navigate(`/${supplierId}`, { replace: true });
        }
    }, [isSuccess, bookingId, isError]);

    useEffect(() => {
        //if bookingId is not valid, navigate to 404 page
        if (!isValidUUID(bookingId) && isValidUUID(supplierId)) {
            navigate(`/${supplierId}`, { replace: true });
        }
    }, [bookingId, supplierId]);

    function intializeSoldRequestData(data: IBookingDetail) {
        const {
            totalNumberOfSoldRequestLeadsFromGivenTimeFrame,
            productCriteriaPotentialPercentage,
            generalCriteriaPotential,
            pricePotentialPercentage,
            zipCodePotentialPercentage,
            weekdayPotentialPercentage
        } = data.preCalculcatedBookingPotentials;

        setSoldRequest({
            totalNumberOfSoldRequest:
                totalNumberOfSoldRequestLeadsFromGivenTimeFrame,
            generalCriteriaPotentialPercentage: generalCriteriaPotential,
            pricePotentialPercentage,
            zipCodePotentialPercentage,
            weekdayPotentialPercentage,
            productCriteriaPotentialPercentage
        });
    }

    function setPotentialNumberOnProductCriteriaValueChange(count: number) {
        setSoldRequest((prevData) => {
            if (!prevData) return undefined;
            return {
                ...prevData,
                productCriteriaPotentialPercentage:
                    prevData.totalNumberOfSoldRequest !== 0
                        ? count / prevData.totalNumberOfSoldRequest
                        : 0
            };
        });
    }

    function setPotentialNumberOnGeneralCriteriaValueChange(
        generalPotential: number,
        weekdayPontential: number
    ) {
        setSoldRequest((prevData) => {
            if (!prevData) return undefined;
            return {
                ...prevData,
                generalCriteriaPotentialPercentage: generalPotential,
                weekdayPotentialPercentage: weekdayPontential
            };
        });
    }

    function setPotentialNumberOnBudgetPriceValueChange(
        pricePotential: number
    ) {
        setSoldRequest((prevData) => {
            if (!prevData) return undefined;
            return {
                ...prevData,
                pricePotentialPercentage: pricePotential
            };
        });
    }

    const setPotentialNumberOnZipCodeSelectionChange = useCallback(
        (pricePotential: number) => {
            setSoldRequest((prevData) => {
                if (!prevData) return undefined;
                return {
                    ...prevData,
                    zipCodePotentialPercentage: pricePotential
                };
            });
        },
        []
    );

    return (
        <>
            {initializeBookingInfo && (
                <>
                    <BookingStickyHeader
                        totalNumberOfRequest={
                            soldRequest?.totalNumberOfSoldRequest
                        }
                        matchNumberOfRequest={
                            soldRequest
                                ? calculateTotalNumberOfMatchRequest(
                                      soldRequest
                                  )
                                : undefined
                        }
                        isSticky={isSticky}
                        budgetInfo={initializeBookingInfo.bookingInfo.budget}
                        cancellationQuote={
                            initializeBookingInfo.bookingInfo.cancellationQuote
                        }
                    />
                    {isSticky && <div className="sticky-placeholder"></div>}
                    <Tabs
                        className="container"
                        value={currentBookingTab}
                        onChange={tabHandleChange}
                        aria-label="booking tabs"
                    >
                        <Tab
                            label="Vorqualifizierung"
                            value={BookingDetailsContainerTab.CRITERIA}
                        />
                        <Tab
                            label="Budgetoptimierung"
                            value={
                                BookingDetailsContainerTab.BudgetOptimization
                            }
                        />
                        <Tab
                            label="Zielgebiet"
                            value={BookingDetailsContainerTab.ZipCode}
                        />
                    </Tabs>
                    <CustomTabPanel
                        className="container"
                        value={currentBookingTab}
                        index={BookingDetailsContainerTab.CRITERIA}
                    >
                        <h2>
                            Prüfen Sie den Einfluss Ihrer Kriterien auf Ihren
                            Marktanteil
                        </h2>
                        <BookingChangesHint />
                        <CriteriaGroup
                            bookingId={bookingId}
                            productCriteriaGroups={convertProductCriteriaDataToPropsData(
                                initializeBookingInfo.productCriteriaInformations
                            )}
                            calculatedCriteriaMatching={convertProductCriteriaMatchingInfoToPropsData(
                                initializeBookingInfo.productCriteriaMatchingInformations
                            )}
                            generalCriteria={convertToGeneralCriteriaPropsData(
                                initializeBookingInfo.bookingInfo
                            )}
                            generalCriteriaPotentialInfos={convertBookingPotentialsToGeneralCriteriaPotentialPropsData(
                                initializeBookingInfo.bookingPotentialMatchingInfomations
                            )}
                            getPotentialNumberOnProductCriteriaValueChange={
                                setPotentialNumberOnProductCriteriaValueChange
                            }
                            getPotentialNumberOnGeneralCriteriaValueChange={
                                setPotentialNumberOnGeneralCriteriaValueChange
                            }
                        />
                    </CustomTabPanel>
                    <CustomTabPanel
                        className="container"
                        value={currentBookingTab}
                        index={BookingDetailsContainerTab.BudgetOptimization}
                    >
                        <BookingPriceOverview
                            cancellationQuote={
                                initializeBookingInfo.bookingInfo
                                    .cancellationQuote
                            }
                            effectiveLeadNettoPriceAdjustmentState={
                                initializeBookingInfo.bookingInfo
                                    .effectiveLeadNettoPriceAdjustmentState
                            }
                            getPotentialNumberOnBookingPriceValueChange={
                                setPotentialNumberOnBudgetPriceValueChange
                            }
                            bookingPricePotentialInfos={convertBookingPotentialsToPricePotentialPropsData(
                                initializeBookingInfo.bookingPotentialMatchingInfomations
                            )}
                            bookingId={bookingId}
                            leadPrice1={
                                initializeBookingInfo.bookingInfo.leadPrice1
                            }
                            leadPrice2={
                                initializeBookingInfo.bookingInfo.leadPrice2
                            }
                            leadPrice3={
                                initializeBookingInfo.bookingInfo.leadPrice3
                            }
                            leadType={
                                initializeBookingInfo.bookingInfo.leadType
                            }
                        />
                    </CustomTabPanel>
                    <CustomTabPanel
                        className="container"
                        value={currentBookingTab}
                        index={BookingDetailsContainerTab.ZipCode}
                    >
                        <BookingZipCodeOverview
                            bookingZipCodePotentialInfos={convertBookingPotentialsToZipCodePotentialPropsData(
                                initializeBookingInfo.bookingPotentialMatchingInfomations
                            )}
                            bookingId={bookingId}
                            bookingAreaInformations={
                                initializeBookingInfo.bookingAreaInformations
                            }
                            getPotentialNumberOnBookingZipCodeSelectionChange={
                                setPotentialNumberOnZipCodeSelectionChange
                            }
                        />
                    </CustomTabPanel>
                </>
            )}
        </>
    );
}

export default BookingDetailsContainer;
