import { Navigate, Route } from "react-router-dom";
import { NonPartneredSupplierContainer } from "../../features/supplierInfo";
import {
    LeadReceiptsContactInformationOverview,
    ZipCodeInformationOverview
} from "../../features/waitingListBookingChangeRequest";
import { WaitingListLeadOverview } from "../../features/waitingListLead";

const WaitingListRoutes = (
    <Route
        path=":supplierId/waitinglistrequests"
        element={<NonPartneredSupplierContainer />}
    >
        <Route index element={<Navigate to="/404" replace />} />
        <Route path=":secureId" element={<WaitingListLeadOverview />} />
        <Route
            path=":secureId/contactform"
            element={<LeadReceiptsContactInformationOverview />}
        />
        <Route
            path=":secureId/zipcodeform"
            element={<ZipCodeInformationOverview />}
        />
    </Route>
);

export default WaitingListRoutes;
