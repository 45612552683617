import { Modal } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import {
    invalidateWaitingListLeadQueries,
    usePatchMakeWaitingListLeadAcquired,
    usePatchMakeWaitingListLeadRejected
} from "../services/waitingListLeadService";
import { useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { Link, useParams } from "react-router-dom";
import { getModalTextsByProductType } from "../utils";

enum Action {
    Capacity = "capacity",
    NoCapacity = "noCapacity"
}

interface Props {
    secureId: string;
    isPersonalProduct: boolean;
}

/**
 *
 * WaitingListLeadStateClosed is a React component that manages and interacts with a waiting list lead in an 'Open' state. It offers the supplier the
 * opportunity to either report available capacities or indicate that no capacities are available.
 *
 */
function WaitingListLeadStateOpen({ secureId,isPersonalProduct }: Readonly<Props>) {
    const queryClient = useQueryClient();
    const location = useLocation();
    const { supplierId } = useParams() as { supplierId: string };
    const searchParams = new URLSearchParams(location.search);
    const [acquiredPopupOpen, setAcquiredPopupOpen] = useState<boolean>(
        checkActionParamExist(Action.Capacity)
    );
    const [rejectPopupOpen, setRejectPopupOpen] = useState<boolean>(
        checkActionParamExist(Action.NoCapacity)
    );

    const { 
        freeCapacityModalTitle, 
        freeCapacityModalDescription, 
        freeCapacityModalCheckBox1Text, 
        freeCapacityModalButtonText ,
        noCapacityModalTitle,
        noCapacityModalLeftDescription,
        noCapacityModalRightDescription,
        noCapacityModalRightCheckbox1,
        noCapacityModalButtonText,
        openCapacityStateHasCapacityButtonText,
        openCapacityStateNoCapacityButtonText,
        openCapacityStateTopDescription
    } = getModalTextsByProductType(isPersonalProduct); 

    const errorMessage = "Fehler beim Melden der Kapazitäten";
    // Api call back methods for acquired and rejected methods
    const apiCallBack = {
        onSuccess: () => {
            invalidateWaitingListLeadQueries(queryClient);
        },
        onError: () => {
            toast.error(errorMessage);
        },
        onSettled: () => {
            setAcquiredPopupOpen(false);
        }
    };

    const { mutate: acquireLead } = usePatchMakeWaitingListLeadAcquired(
        secureId,
        apiCallBack
    );

    const { mutate: rejectLead } = usePatchMakeWaitingListLeadRejected(
        secureId,
        apiCallBack
    );

    function checkActionParamExist(action: Action): boolean {
        return (
            searchParams.get("action")?.toLowerCase() === action.toLowerCase()
        );
    }

    return (
        <div>
            <div className="flex-nowrap align-center">
                <div className="icon icon-email"></div>
                <h3 className="highlight-green">
                    Sie haben eine neue Anfrage erhalten.
                </h3>
            </div>
            <p className="text-black" dangerouslySetInnerHTML={{ __html: openCapacityStateTopDescription }}></p>
            <div className="button-group">
                <button
                    className="btn btn-medium"
                    onClick={() => {
                        setAcquiredPopupOpen(true);
                    }}
                >
                    {openCapacityStateHasCapacityButtonText}
                </button>
                <button
                    className="btn btn-info btn-medium"
                    onClick={() => {
                        setRejectPopupOpen(true);
                    }}
                >
                   {openCapacityStateNoCapacityButtonText}
                </button>
            </div>
            <Modal
                className="waiting-list-lead-state-modal-container"
                open={acquiredPopupOpen}
                onClose={() => {
                    setAcquiredPopupOpen(false);
                }}
                aria-labelledby="acquired-popup"
                aria-describedby="acquired-popup-information"
            >
                <div className="waiting-list-lead-state-modal-body">
                    <div
                        className="icon icon-close"
                        onClick={() => setAcquiredPopupOpen(false)}
                    ></div>
                    <h2 className="highlight-blue" dangerouslySetInnerHTML={{ __html: freeCapacityModalTitle }}></h2>
                    <p className="text-black" dangerouslySetInnerHTML={{ __html: freeCapacityModalDescription }}></p>
                    <ul className="list-check">
                        <li>{freeCapacityModalCheckBox1Text}</li>
                        <li>Schnell & unkompliziert</li>
                        <li>100 % kostenlos</li>
                    </ul>
                    <button
                        className="btn btn-medium"
                        onClick={() => {
                            acquireLead();
                        }}
                    >
                        {freeCapacityModalButtonText}
                    </button>
                </div>
            </Modal>
            <Modal
                className="waiting-list-lead-state-modal-container"
                open={rejectPopupOpen}
                onClose={() => {
                    setRejectPopupOpen(false);
                }}
                aria-labelledby="acquired-popup"
                aria-describedby="acquired-popup-information"
            >
                <div className="waiting-list-lead-state-modal-body">
                    <div
                        className="icon icon-close"
                        onClick={() => setRejectPopupOpen(false)}
                    ></div>
                    <div className="reject-body">
                        <div>
                            <h2 id="no-capacity-title">
                                {noCapacityModalTitle}
                            </h2>
                            <p className="mtb-30 mr-10-desktop" dangerouslySetInnerHTML={{ __html: noCapacityModalLeftDescription }}>
                            </p>
                            <button
                                className="btn btn-info btn-medium"
                                onClick={() => rejectLead()}
                            >
                                {noCapacityModalButtonText}
                            </button>
                        </div>

                        <div className="waiting-list-lead-reject-box">
                            <h3>Sind Sie sich sicher?</h3>
                            <p dangerouslySetInnerHTML={{ __html: noCapacityModalRightDescription }}>
                            </p>
                            <ul className="list-check">
                                <li>{noCapacityModalRightCheckbox1}</li>
                                <li>Schnell & unkompliziert</li>
                                <li>100 % kostenlos</li>
                            </ul>
                            <a
                                onClick={() => setRejectPopupOpen(false)}
                                className="highlight-blue "
                            >
                                <h3>Zurück zur Anfrage »</h3>
                            </a>
                        </div>
                    </div>
                </div>
            </Modal>

            <div className="text-black mtb-30">
                Sie sind nicht der korrekte Ansprechpartner? &nbsp;
                <Link
                    to={`/${supplierId}/waitinglistrequests/${secureId}/contactform`}
                    className="link"
                >
                    Kontakdaten aktualisieren{" "}
                    <i
                        className="icon icon-external-link"
                        aria-hidden="true"
                    ></i>
                </Link>
            </div>
        </div>
    );
}

export default WaitingListLeadStateOpen;
