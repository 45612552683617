import {
    ApplicationInsights,
    IExceptionTelemetry,
    SeverityLevel
} from "@microsoft/applicationinsights-web";

// Initialize Application Insights
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
        disableFetchTracking: false, // Enable tracking for Fetch API calls
        enableAutoRouteTracking: true, // For tracking page views in SPA
        samplingPercentage: 100, // Control the volume of telemetry (100 = no sampling)
        disableExceptionTracking: false, // You would keep this enabled as you want to track exceptions
        autoTrackPageVisitTime: false, // Automatically track the time user spent on each page
        enableDebug: false, // Enable debug mode to see more logs in the console
        maxBatchSizeInBytes: 10000, // Maximum size of a telemetry batch
        maxBatchInterval: 15000 // How often telemetry is sent to the server
    }
});

if (process.env.NODE_ENV !== "development" && process.env.NODE_ENV !== "test") {
    appInsights.loadAppInsights();
}

// Function to track exceptions
export function trackException(
    exception: Error,
    severityLevel?: SeverityLevel
): void {
    const customProperties = {
        errorName: "Anfragen Manager Error",
        url: window.location.href
    };
    const exceptionTelemetry: IExceptionTelemetry = {
        exception,
        severityLevel,
        properties: customProperties
    };
    if (process.env.NODE_ENV !== "development") {
        appInsights.trackException(exceptionTelemetry);
    }
}
