import { useState } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import {
    IProductCriteriaOption,
    IProductCriteriaOptionConfiguration
} from "../types/interfaces";

interface Props {
    criteriaId: string;
    optionConfiguration: IProductCriteriaOptionConfiguration;
    onClick: (criteriaId: string, options: IProductCriteriaOption[]) => void;
}

/**
 * MultiSelectCheckBox is a React component that renders a group of checkboxes representing selectable
 * options for a specific product criterion. It manages the
 * selection state of each option and provides a mechanism to report changes to a parent component for potential calculations.
 *
 */

function MultiSelectCheckBox({
    criteriaId,
    optionConfiguration,
    onClick
}: Readonly<Props>) {
    const [options, setOptions] = useState<IProductCriteriaOption[]>(
        optionConfiguration.options
    );

    const handleOptionChange = (optionId: string, checked: boolean) => {
        const modifiedOptions = options.map((option) =>
            option.optionId === optionId
                ? { ...option, isSelected: checked }
                : option
        );
        setOptions(modifiedOptions);
        onClick(criteriaId, modifiedOptions);
    };

    return (
        <FormGroup>
            {options.map((option) => (
                <FormControlLabel
                    key={option.optionId}
                    hidden={option.isHidden}
                    control={
                        <Checkbox
                            checked={option.isSelected}
                            onChange={(e) =>
                                handleOptionChange(
                                    option.optionId,
                                    e.target.checked
                                )
                            }
                        />
                    }
                    label={option.optionsName}
                />
            ))}
        </FormGroup>
    );
}

export default MultiSelectCheckBox;
