interface TabPanelProps extends React.HTMLProps<HTMLDivElement> {
    children?: React.ReactNode;
    index: React.AllHTMLAttributes<HTMLInputElement>["value"];
}

/**
 *
 * CustomTabPanel is a React component that serves as an individual panel within a tabbed interface in a web application. It is designed to work
 * in conjunction with tab controls that determine which tab panel is currently visible. The `CustomTabPanel` manages
 * the display of its contents based on the active tab state, ensuring that only the content of the currently active tab
 * is visible.
 */
function CustomTabPanel(props: Readonly<TabPanelProps>) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}

export default CustomTabPanel;
