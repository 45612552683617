import {
    Box,
    Button,
    Modal,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@mui/material";
import React, { useState } from "react";
import { ZipCodeFormViewType } from "../types/zipCodeModalContainer/enums";
import { IAreaInformation } from "../types";
import ZipCodeByAreaName from "./ZipCodeByAreaName";
import "../../../assets/styles/zipCode/ZipCodeModalContainer.scss";
import ZipCodeByStartWith from "./ZipCodeByStartWith";
import ZipCodeByStartWithAndEndWith from "./ZipCodeByStartWithAndEndWith";
import ZipCodeByRadius from "./ZipCodeByRadius";
import ZipCodeCustom from "./ZipCodeCustom";

interface Props {
    addZipCodeGroupOnSuccess: (
        areaId: string,
        name: string,
        totalNumberOfZipCode: number,
        zipCodeList: string[]
    ) => void;
}

/**
 *
 * ZipCodeModalContainer is a React component that provides a modal interface for users to add new zip code groups. It supports
 * various methods of specifying zip codes, such as by area name, specific zip code ranges, or custom definitions.
 * The modal can be triggered via a button and offers multiple input forms depending on the selection type.
 * Callback function that is invoked when a new zip code group is successfully added.
 * This function updates the list of postal codes and their respective group information based on the user input.
 */
function ZipCodeModalContainer({ addZipCodeGroupOnSuccess }: Readonly<Props>) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [zipCodeFormViewSelectedType, setZipCodeFormViewSelectedType] =
        useState<ZipCodeFormViewType>(
            ZipCodeFormViewType.ZipCodeListByAreaName
        );

    function handleAddZipCodeGroup(areaInformation: IAreaInformation) {
        addZipCodeGroupOnSuccess(
            areaInformation.areaId,
            areaInformation.name,
            areaInformation.totalNumberOfZipCode,
            areaInformation.zipCodeList
        );
        setIsModalOpen(false);
    }

    function zipCodeFormViewOnChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setZipCodeFormViewSelectedType(
            event.target.value as ZipCodeFormViewType
        );
    }

    function getSelectedClassName(zipCodeFormViewType: ZipCodeFormViewType) {
        return zipCodeFormViewType === zipCodeFormViewSelectedType
            ? "selected"
            : "";
    }

    return (
        <div>
            <Box className="button-group end mw-1400 mb-50">
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    + Zielgebiet hinzufügen
                </Button>
            </Box>
            {isModalOpen && (
                <Modal
                    className="zipcode-modal-container"
                    open={isModalOpen}
                    onClose={() => {}}
                    aria-labelledby="zip-code-modal"
                    aria-describedby="zip-code-modal-description"
                    disableEscapeKeyDown
                >
                    <Box className="zipcode-modal-body">
                        <h2>Zielgebiet hinzufügen</h2>
                        <div className="highlight-blue">Gruppe wählen:</div>
                        <RadioGroup
                            row
                            aria-label="group"
                            value={zipCodeFormViewSelectedType}
                            onChange={zipCodeFormViewOnChange}
                            className="zipcode-radio-group-conainer mb-20"
                        >
                            <FormControlLabel
                                value={
                                    ZipCodeFormViewType.ZipCodeListByAreaName
                                }
                                control={<Radio />}
                                label="Bundesland / Stadt / Stadtteil"
                                className={
                                    "zipcode-radio-conainer " +
                                    getSelectedClassName(
                                        ZipCodeFormViewType.ZipCodeListByAreaName
                                    )
                                }
                            />
                            <FormControlLabel
                                value={
                                    ZipCodeFormViewType.ZipCodeListByStartWith
                                }
                                control={<Radio />}
                                label="Postleitzahl(*)"
                                className={
                                    "zipcode-radio-conainer " +
                                    getSelectedClassName(
                                        ZipCodeFormViewType.ZipCodeListByStartWith
                                    )
                                }
                            />
                            <FormControlLabel
                                value={
                                    ZipCodeFormViewType.ZipCodeListByStartWithAndEndWith
                                }
                                control={<Radio />}
                                label="Postleitzahl (von-bis)"
                                className={
                                    "zipcode-radio-conainer " +
                                    getSelectedClassName(
                                        ZipCodeFormViewType.ZipCodeListByStartWithAndEndWith
                                    )
                                }
                            />
                            <FormControlLabel
                                value={ZipCodeFormViewType.ZipCodeListByRadius}
                                control={<Radio />}
                                label="Umkreis"
                                className={
                                    "zipcode-radio-conainer " +
                                    getSelectedClassName(
                                        ZipCodeFormViewType.ZipCodeListByRadius
                                    )
                                }
                            />
                            <FormControlLabel
                                value={ZipCodeFormViewType.ZipCodeListCustom}
                                control={<Radio />}
                                label="Benutzerdefiniert"
                                className={
                                    "zipcode-radio-conainer " +
                                    getSelectedClassName(
                                        ZipCodeFormViewType.ZipCodeListCustom
                                    )
                                }
                            />
                        </RadioGroup>
                        {zipCodeFormViewSelectedType ===
                            ZipCodeFormViewType.ZipCodeListByAreaName && (
                            <ZipCodeByAreaName
                                handleModalCloseEvent={() =>
                                    setIsModalOpen(false)
                                }
                                handleZipCodeGroupAdd={handleAddZipCodeGroup}
                            />
                        )}
                        {zipCodeFormViewSelectedType ===
                            ZipCodeFormViewType.ZipCodeListByStartWith && (
                            <ZipCodeByStartWith
                                handleModalCloseEvent={() =>
                                    setIsModalOpen(false)
                                }
                                handleZipCodeGroupAdd={handleAddZipCodeGroup}
                            />
                        )}
                        {zipCodeFormViewSelectedType ===
                            ZipCodeFormViewType.ZipCodeListByStartWithAndEndWith && (
                            <ZipCodeByStartWithAndEndWith
                                handleModalCloseEvent={() =>
                                    setIsModalOpen(false)
                                }
                                handleZipCodeGroupAdd={handleAddZipCodeGroup}
                            />
                        )}
                        {zipCodeFormViewSelectedType ===
                            ZipCodeFormViewType.ZipCodeListByRadius && (
                            <ZipCodeByRadius
                                handleModalCloseEvent={() =>
                                    setIsModalOpen(false)
                                }
                                handleZipCodeGroupAdd={handleAddZipCodeGroup}
                            />
                        )}
                        {zipCodeFormViewSelectedType ===
                            ZipCodeFormViewType.ZipCodeListCustom && (
                            <ZipCodeCustom
                                handleModalCloseEvent={() =>
                                    setIsModalOpen(false)
                                }
                                handleZipCodeGroupAdd={handleAddZipCodeGroup}
                            />
                        )}
                    </Box>
                </Modal>
            )}
        </div>
    );
}

export default ZipCodeModalContainer;
