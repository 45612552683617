export default class ApiError extends Error {
    status: number;
    data: any;

    constructor(message?: string, status: number = 0, data: any = {}) {
        super(message ?? "An unknown error occurred");
        this.status = status;
        this.data = data;

        // Set the prototype explicitly
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}
