import {
    FormControlLabel,
    Checkbox,
    Slider,
    Tooltip,
    Box
} from "@mui/material";
import "../../../assets/styles/booking/BookingSinglePrice.scss";
import { LeadType } from "../types/enums";
import { useMemo } from "react";

interface Props {
    bookingId: string;
    priceLabel: string;
    checkBoxChecked: boolean;
    checkBoxDisabled: boolean;
    sliderValue: number;
    sliderMinValue: number;
    leadType: LeadType;
    onCheckboxChanged: (checked: boolean, leadType: LeadType) => void;
    onSliderValueChanged: (sliderValue: number, leadType: LeadType) => void;
}

/**
 * BookingSinglePrice is a React component designed to provide a specific interface for adjusting the price of
 * a single lead type within a booking system. It includes a checkbox to enable or disable the price setting,
 * and a slider to adjust the lead price.
 *
 * The component leverages useMemo for efficient re-rendering, ensuring that the UI components are only updated
 * once. It also uses callbacks for user interactions, handling changes in checkbox state and slider
 * values, thereby updating the lead price configuration in real time.
 *
 */
function BookingSinglePrice({
    bookingId,
    priceLabel,
    checkBoxChecked,
    checkBoxDisabled,
    sliderValue,
    sliderMinValue,
    leadType,
    onCheckboxChanged,
    onSliderValueChanged
}: Readonly<Props>) {
    const maxValue = 500;
    const minValue = useMemo(() => {
        return sliderMinValue;
    }, [bookingId]);

    const marks = useMemo(() => {
        return Array.from({ length: maxValue - sliderValue + 1 }, (_, i) => {
            return {
                value: sliderValue + i
            };
        });
    }, [bookingId]);

    function valueLabelFormat(value: number) {
        return `${value} €`;
    }

    function handleCheckboxChange(
        event: React.ChangeEvent<HTMLInputElement>
    ): void {
        onCheckboxChanged(event.target.checked, leadType);
    }

    const handleSliderValueChange = (value: number | number[]) => {
        if (Array.isArray(value)) {
            throw new Error("Slider value should not be an array");
        }
        onSliderValueChanged(value, leadType);
    };

    return (
        <div className="mb-20 booking-single-price">
            <FormControlLabel
                className="mb-20"
                control={
                    <Checkbox
                        checked={checkBoxChecked}
                        onChange={handleCheckboxChange}
                        disabled={checkBoxDisabled}
                    />
                }
                label={priceLabel}
                disabled={checkBoxDisabled}
            />
            <div
                className={`single-budget-slider-container ${
                    checkBoxChecked ? "grid-two-columns" : ""
                }`}
                hidden={!checkBoxChecked}
            >
                <div>
                    <div className="highlight-blue">
                        Leadpreis
                        <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={10000}
                            arrow
                            title={
                                <div>
                                    Mit dem Leadpreis legen Sie fest, wie viel
                                    Sie für einen Lead zahlen möchten. <br />
                                    <b>Unser Tipp:</b> Steigern Sie Ihren
                                    Leadpreis, um einen höheren Marktanteil zu
                                    erzielen.
                                </div>
                            }
                        >
                            <div className="information-icon"></div>
                        </Tooltip>
                    </div>
                </div>
                <div>
                    <Box display="flex" alignItems="center">
                        <Box minWidth="40px" mr={0.8}>
                            {valueLabelFormat(minValue)}
                        </Box>
                        <Slider
                            marks={marks}
                            step={null}
                            className="show-label"
                            valueLabelDisplay="on"
                            valueLabelFormat={valueLabelFormat}
                            value={sliderValue}
                            onChange={(_, newValue) => {
                                if (Array.isArray(newValue)) {
                                    throw new Error(
                                        "Slider value should not be an array"
                                    );
                                }
                                handleSliderValueChange(newValue);
                            }}
                            track={false}
                            min={minValue}
                            max={maxValue}
                        />
                        <Box minWidth="40px" ml={1.5}>
                            {valueLabelFormat(maxValue)}
                        </Box>
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default BookingSinglePrice;
