import { IProductPotentialState } from "../../components/BookingDetailsContainer";

export function calculateTotalNumberOfMatchRequest(
    productPotential: IProductPotentialState
): number {
    return Math.round(
        productPotential.generalCriteriaPotentialPercentage *
            productPotential.pricePotentialPercentage *
            productPotential.weekdayPotentialPercentage *
            productPotential.zipCodePotentialPercentage *
            productPotential.productCriteriaPotentialPercentage *
            productPotential.totalNumberOfSoldRequest
    );
}
