import { useEffect, useState, useMemo } from "react";

/**
 * useBookingSticky is a custom React hook designed to manage the sticky behavior of UI components
 * based on the user's scroll position relative to a specified header height.
 *
 *
 * Returns:
 *  - isSticky: A boolean state that indicates whether the sticky behavior should be active or not.
 *
 */
function useBookingSticky() {
    const [isSticky, setIsSticky] = useState(false);
    const headerHeight = useMemo(() => getHeaderHeight(), []);

    function getHeaderHeight(): number {
        const fixedHeight = 88;
        if (window.innerWidth <= 992) {
            const sidebar = document.querySelector<HTMLElement>(".sidebar");
            if (sidebar) {
                return fixedHeight + sidebar.offsetHeight;
            }
        }
        return fixedHeight;
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > headerHeight) setIsSticky(true);
            else setIsSticky(false);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [headerHeight]);

    return { isSticky };
}

export default useBookingSticky;
