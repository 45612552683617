import "../../assets/styles/components/SideNavbar.scss";

interface Props {
    name: string;
    className?: string;
    children?: React.ReactNode;
}

/**
 *
 * SideNavbar is a React component that serves as a sidebar navigation container in our application. It is designed to provide a
 * structured area for navigation links or other sidebar content.
 *
 */
function SideNavbar({ name, className, children }: Readonly<Props>) {
    return (
        <div className={`sidebar ${className}`}>
            <h1 className="sidebar-title">{name}</h1>
            {children}
        </div>
    );
}

export default SideNavbar;
