import { BudgetUnit } from "../../types/enums";

export function getTextByBudgetUnit(unit: BudgetUnit): string {
    switch (unit) {
        case BudgetUnit.Monthly:
            return "Monat";
        case BudgetUnit.Daily:
            return "Tag";
        case BudgetUnit.Weekly:
            return "Woche";
        default:
            throw new Error(`Unknown BudgetUnit value: ${unit}`);
    }
}
