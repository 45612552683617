import { IProductCriteriaDataManagementService } from "../service/productCriteriaService";
import { InputType } from "./enum/inputType";
import {
    IOptionConfiguration,
    OptionConfiguration
} from "./optionConfiguration";
import {
    ISliderConfiguration,
    SliderConfiguration
} from "./sliderConfiguration";

interface ICriteriaPresentation {
    groupName: string;
    groupOrderIndex: number;
}
/**
 * @interface ICriteria
 * Represents a set of criteria that define requirements for matching requests (for booking side) or bookings (for request side).
 * @property {string} criteriaName - The name or label of the criteria.
 * @property {string} criteriaId - The unique identifier of the criteria.
 * @property {number} inputType - The input type of the criteria, indicating the nature of user input.
 * @property {boolean} isHidden - Indicates whether the criteria is hidden from user view.
 * @property {ISliderConfiguration} sliderConfiguration - Configuration settings for slider input type.
 * @property {IOptionConfiguration} optionConfiguration - Configuration settings for option input type.
 * @property {number} currentLocalPotential - The number of potential matches based on the current criteria values if only this criteria is considered.
 */
export interface ICriteria extends ICriteriaPresentation {
    criteriaName: string;
    criteriaId: string;
    inputType: number;
    isHidden: boolean;

    sliderConfiguration: ISliderConfiguration;
    optionConfiguration: IOptionConfiguration;

    /** How would many requests / bookings match based on the current criteria values
     * if we only consider this one criteria? */
    currentLocalPotential: number;

    isCriteriaMatchedByIndex(index: number): boolean;
}

export class Criteria implements ICriteria {
    public groupName: string;
    public groupOrderIndex: number;

    public criteriaName: string;
    public criteriaId: string;
    public inputType: InputType;
    public isHidden: boolean;

    public sliderConfiguration!: ISliderConfiguration;
    public optionConfiguration!: IOptionConfiguration;
    public currentLocalPotential: number = -1;

    constructor(
        objFromJson: ICriteria,
        productCriteriaDataManagementService: IProductCriteriaDataManagementService
    ) {
        this.groupName = objFromJson.groupName;
        this.groupOrderIndex = objFromJson.groupOrderIndex;

        this.criteriaName = objFromJson.criteriaName;
        this.criteriaId = objFromJson.criteriaId;
        this.inputType = objFromJson.inputType;
        this.isHidden = objFromJson.isHidden;
        if (objFromJson.sliderConfiguration)
            this.sliderConfiguration = new SliderConfiguration(
                objFromJson.sliderConfiguration,
                this.criteriaId,
                productCriteriaDataManagementService
            );
        if (objFromJson.optionConfiguration)
            this.optionConfiguration = new OptionConfiguration(
                objFromJson.optionConfiguration,
                this.criteriaId,
                productCriteriaDataManagementService
            );
    }

    isCriteriaMatchedByIndex(index: number): boolean {
        if (this.inputType == InputType.Options) {
            return this.optionConfiguration.isCriteriaMatchedByIndex(index);
        } else {
            return this.sliderConfiguration.isCriteriaMatchedByIndex(index);
        }
    }
}
