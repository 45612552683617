import {
    ICriteria,
    IOption,
    IOptionConfiguration,
    ISliderConfiguration
} from "../../../../lib";
import { IProductCriteriaGroup } from "../../types/interfaces";

export function convertGroupToProductServiceCriterias(
    productGroups: IProductCriteriaGroup[]
): ICriteria[] {
    // First, extract all the criteria from the product groups.
    const allCriteria = productGroups.flatMap(
        (productGroup) => productGroup.criteriaList
    );

    // Convert all extracted criteria to the new format.
    return allCriteria.map((iProductCriteria) => {
        if (iProductCriteria.optionConfiguration) {
            const options = iProductCriteria.optionConfiguration.options.map(
                (option) => {
                    return {
                        optionsName: option.optionsName,
                        optionId: option.optionId,
                        isSelected: option.isSelected,
                        implies: option.implies,
                        isHidden: option.isHidden
                    } as IOption;
                }
            );
            return {
                criteriaName: iProductCriteria.criteriaName,
                criteriaId: iProductCriteria.criteriaId,
                inputType: iProductCriteria.inputType,
                isHidden: iProductCriteria.isHidden,
                optionConfiguration: {
                    representAsList:
                        iProductCriteria.optionConfiguration.representAsList,
                    multipleValuesAllowed:
                        iProductCriteria.optionConfiguration
                            .multipleValuesAllowed,
                    reverseExcludeOrder:
                        iProductCriteria.optionConfiguration
                            .reverseExcludeOrder,
                    operatorRule:
                        iProductCriteria.optionConfiguration.operatorRule,
                    options: options
                } as IOptionConfiguration
            } as ICriteria;
        } else {
            return {
                criteriaName: iProductCriteria.criteriaName,
                criteriaId: iProductCriteria.criteriaId,
                inputType: iProductCriteria.inputType,
                isHidden: iProductCriteria.isHidden,
                sliderConfiguration: {
                    min: iProductCriteria.sliderConfiguration?.min,
                    max: iProductCriteria.sliderConfiguration?.max
                } as ISliderConfiguration
            } as ICriteria;
        }
    });
}
