import "../../assets/styles/components/Header.scss";
import MobileNavIcon from "./MobileNavIcon";

interface Props {
    name?: string;
    image?: string;
    isPartnered: boolean;
    showNavigationDrawerIcon?: boolean;
}

/**
 * Header is a React component that provides a configurable header section
 * for the application. It dynamically displays contact information and an image
 * based on the supplied props. The component adjusts its content and layout
 * based on whether the user is a partnered supplier and whether to show
 * navigation drawer icons on smaller screens.
 *
 */
function Header({
    name,
    image,
    isPartnered,
    showNavigationDrawerIcon
}: Readonly<Props>) {
    function renderImage() {
        if (image) {
            return (
                <img className="header-contact-image" src={image} alt={name} />
            );
        } else {
            return <img className="header-contact-no-image"></img>;
        }
    }

    function showSupplierInfo() {
        return (
            <>
                <div className="header-contact">
                    <div className="header-contact-title">
                        Ihr/e Ansprechpartner/in
                    </div>
                    <div className="header-contact-name">{name}</div>
                    <div className="header-contact-phone">
                        <a href={"tel:+4961312652011"}>06131 / 26 52 011</a>
                    </div>
                </div>
                {renderImage()}
            </>
        );
    }

    function showNonSupplierInfo() {
        return (
            <div
                className={`header-contact non-supplier-info ${
                    showNavigationDrawerIcon ? "hidden-small-screen" : ""
                }`}
            >
                <div className="header-contact-title">
                    Beratung zur Mitgliedschaft
                </div>
                <div className="header-contact-phone">
                    <a href={"tel:+4961312652010"}>06131 / 26 52 010</a>
                </div>
                <div className="header-phone-add">
                    Mo – Fr von 8:00 bis 18:00 Uhr{" "}
                </div>
            </div>
        );
    }

    return (
        <div className="header-container">
            <div className="header">
                <a>
                    <div className="header-logo"></div>
                </a>
                <div className="header-contact-container">
                    {isPartnered ? showSupplierInfo() : showNonSupplierInfo()}
                    {!isPartnered && showNavigationDrawerIcon && (
                        <MobileNavIcon className="show-small-screen"></MobileNavIcon>
                    )}
                </div>
            </div>
            <div className="header-banner"></div>
        </div>
    );
}

export default Header;
