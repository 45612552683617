import "../../../assets/styles/waitingListLead/WaitingListLeadState.scss";
import { getModalTextsByProductType } from "../utils";

/**
 *
 * WaitingListLeadStateClosed is a React component that displays a notification to inform
 * suppliers that a specific waiting list lead has been closed by the client.
 */

interface Props {
    isPersonalProduct: boolean;
}

function WaitingListLeadStateClosed({isPersonalProduct}:Readonly<Props>){

    const { 
        closedStateTopMessage,
        closedStateRedBox,
    } = getModalTextsByProductType(isPersonalProduct); 

    return (
        <div className="waiting-list-lead-closed-container">
            <div className="flex-nowrap align-center mb-20">
                <div className="icon icon-close-pink"></div>
                <h3 className="text-light-orange">
                    {closedStateTopMessage}
                </h3>
            </div>
            <div className="waiting-list-lead-status-box close-box mb-20 ">
                <div>
                    <h3>Sie waren leider zu spät ...</h3>
                    {closedStateRedBox}
                </div>
            </div>
        </div>
    );
}

export default WaitingListLeadStateClosed;
