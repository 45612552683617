import { QueryClient, useQuery } from "@tanstack/react-query";
import { get } from "../../../lib";
import {
    getGetAreasBySearch,
    getGetPostCodeValid,
    getGetPostCodesByGeoItemId,
    getGetPostCodesByRadius,
    getGetPostCodesByStartsWith
} from "../../../constants/reactQueryKey";
import { IGeoItemSearch, IZipCode } from "./dtos";
import { GermanCountryIso2 } from "../../../constants";

export const useGetSearchInfoByAreaTypeAndName = (
    areaType: string,
    search: string,
    countryIso2: string = GermanCountryIso2,
    options = {}
) => {
    return useQuery(
        [getGetAreasBySearch, areaType, search],
        async () => {
            return await get<IGeoItemSearch[]>(
                `geo/${areaType}/${countryIso2}/${search}`
            );
        },
        {
            ...options
        }
    );
};

export const useGetZipCodesByGeoItemId = (geoItemId: string, options = {}) => {
    return useQuery(
        [getGetPostCodesByGeoItemId, geoItemId],
        async () => {
            return await get<IZipCode[]>(
                `geo/postalCodes/byGeoItemId/${geoItemId}`
            );
        },
        {
            ...options
        }
    );
};

export const useGetZipCodesByStartsWith = (
    zipCodePrefix: string,
    countryIso2: string = GermanCountryIso2,
    options = {}
) => {
    return useQuery(
        [getGetPostCodesByStartsWith, zipCodePrefix],
        async () => {
            return await get<IZipCode[]>(
                `geo/postalCodes/byStartsWith/${zipCodePrefix}/${countryIso2}`
            );
        },
        {
            ...options
        }
    );
};

export const useGetZipCodesByStartsWithAndEndsWith = (
    zipCodeStartRange: string,
    zipCodeEndRange: string,
    countryIso2: string = GermanCountryIso2,
    options = {}
) => {
    return useQuery(
        [getGetPostCodesByStartsWith, zipCodeStartRange, zipCodeEndRange],
        async () => {
            return await get<IZipCode[]>(
                `geo/postalCodes/byStartsWithAndEndWith/${zipCodeStartRange}-${zipCodeEndRange}/${countryIso2}`
            );
        },
        {
            ...options
        }
    );
};

export const isZipCodeValid = async (
    queryClient: QueryClient,
    zipCode: string,
    countryIso2: string = GermanCountryIso2,
    options = {}
) => {
    const data = await queryClient.fetchQuery(
        [getGetPostCodeValid, zipCode],
        async () => {
            return await get<boolean>(
                `geo/postalCodes/${zipCode}/${countryIso2}/validate`
            );
        },
        { ...options, cacheTime: 60 * 1000 }
    );
    return data;
};

export const useGetZipCodesByRadius = (
    zipCode: string,
    radius: number,
    countryIso2: string = GermanCountryIso2,
    options = {}
) => {
    return useQuery(
        [getGetPostCodesByRadius, zipCode, radius],
        async () => {
            return await get<IZipCode[]>(
                `geo/postalcodes/byRadius/${zipCode}-${countryIso2}/${radius}`
            );
        },
        {
            ...options
        }
    );
};
