import { Box, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useFormValidationRules } from "../hooks/leadReceiptsContactInformationForm/useFormValidationRules";

export interface ILeadReceiptsContactInformationFormData {
    name: string;
    emailAddress: string;
    mobileNumber: string;
    faxNumber: string;
    isMobileNumberActive: boolean;
    isFaxNumberActive: boolean;
}

interface ILeadReceiptsContactInformationFormProps {
    onSubmit: (
        leadReceiptsContactInformationFormData: ILeadReceiptsContactInformationFormData
    ) => void;
    leadReceiptsContactInformationFormData?: ILeadReceiptsContactInformationFormData;
    contactId: string;
}

function LeadReceiptsContactInformationForm({
    onSubmit,
    leadReceiptsContactInformationFormData,
    contactId
}: Readonly<ILeadReceiptsContactInformationFormProps>) {
    const {
        handleSubmit,
        formState: { isDirty },
        control,
        watch,
        trigger,
        reset
    } = useForm<ILeadReceiptsContactInformationFormData>({
        mode: "onBlur"
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (leadReceiptsContactInformationFormData) {
            reset(leadReceiptsContactInformationFormData);
        }
    }, [contactId, reset]);

    //the trigger will not work in onchange of checkbox
    //because the setvalue will not be updated immediately
    const isFaxNumberActive = watch("isFaxNumberActive");
    useEffect(() => {
        trigger("faxNumber");
    }, [isFaxNumberActive, trigger]);

    //Trigger wont work in onchange because setvalue not updated immediately
    const isMobileNumberActive = watch("isMobileNumberActive");
    useEffect(() => {
        trigger("mobileNumber");
    }, [isMobileNumberActive, trigger]);

    const { emailAddressRules, mobileNumberRules, faxNumberRules } =
        useFormValidationRules({ isMobileNumberActive, isFaxNumberActive });
    return (
        //using controller to control the form fields for managing controlled components like checkbox
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="info-section">
                <div className="flex">
                    <div className="col-6 mr-15-mobile">
                        <label className="bolder">Name:</label>
                        <Box className="input">
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="empty-bg-change"
                                        placeholder="Name"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                    </div>
                    <div className="col-6">
                        <label className="bolder">E-Mail-Adresse*:</label>
                        <Box className="input">
                            <Controller
                                name="emailAddress"
                                control={control}
                                defaultValue=""
                                rules={emailAddressRules}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        placeholder="E-Mail-Adresse"
                                        variant="outlined"
                                        className="empty-bg-change"
                                        fullWidth
                                        error={!!error}
                                        helperText={
                                            error ? error.message : null
                                        }
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                        <span className="text-align-right">
                            <i className="text-align-right">*Pflichtfeld</i>
                        </span>
                    </div>
                </div>

                <div className="flex">
                    <div className="col-6 mr-15-mobile">
                        <label className="bolder">Mobilnummer:</label>
                        <Box className="input">
                            <Controller
                                name="mobileNumber"
                                control={control}
                                defaultValue=""
                                rules={mobileNumberRules}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Mobilnummer"
                                        className="empty-bg-change"
                                        variant="outlined"
                                        error={!!error}
                                        helperText={
                                            error ? error.message : null
                                        }
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                        <Box className="input">
                            <Controller
                                name="isMobileNumberActive"
                                control={control}
                                defaultValue={false}
                                render={({
                                    field: { value, onChange, onBlur, ref }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                inputRef={ref}
                                            />
                                        }
                                        label="Anfragen über SMS aktivieren"
                                    />
                                )}
                            />
                        </Box>
                    </div>

                    <div className="col-6">
                        <label className="bolder">Faxnummer:</label>
                        <Box className="input">
                            <Controller
                                name="faxNumber"
                                control={control}
                                defaultValue=""
                                rules={faxNumberRules}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        className="empty-bg-change"
                                        placeholder="Faxnummer"
                                        variant="outlined"
                                        error={!!error}
                                        helperText={
                                            error ? error.message : null
                                        }
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                        <Box className="input">
                            <Controller
                                name="isFaxNumberActive"
                                control={control}
                                defaultValue={false}
                                render={({
                                    field: { value, onChange, onBlur, ref }
                                }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                inputRef={ref}
                                            />
                                        }
                                        label="Anfragen über Fax aktivieren"
                                    />
                                )}
                            />
                        </Box>
                    </div>
                </div>

                <div className="contact-form-button-container">
                    <button
                        type="submit"
                        className="contact-form-button btn"
                        disabled={!isDirty}
                    >
                        Speichern
                    </button>
                </div>
            </div>
            <div>
                <i>
                    <u>Hinweis</u>: Bitte beachten Sie, dass die endgültige
                    Speicherung Ihrer Anpassungen mehrere Tage in Anspruch
                    nehmen kann.
                </i>
            </div>
        </form>
    );
}

export default LeadReceiptsContactInformationForm;
