import { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import {
    IProductCriteriaOption,
    IProductCriteriaOptionConfiguration
} from "../types/interfaces";

interface Props {
    criteriaId: string;
    optionConfiguration: IProductCriteriaOptionConfiguration;
    onClick: (criteriaId: string, options: IProductCriteriaOption[]) => void;
}

/**
 * SingleSelectRadioButton is a React component that renders a group of radio buttons representing selectable
 * options for a specific product criterion. It manages the
 * selection state of each option and provides a mechanism to report changes to a parent component for potential calculations.
 *
 */
function SingleSelectRadioButton({
    criteriaId,
    optionConfiguration,
    onClick
}: Readonly<Props>) {
    const [options, setOptions] = useState<IProductCriteriaOption[]>(
        optionConfiguration.options
    );

    const handleOptionChange = (optionId: string) => {
        const modifiedOptions = options.map((option) => {
            return { ...option, isSelected: option.optionId === optionId };
        });
        setOptions(modifiedOptions);
        onClick(criteriaId, modifiedOptions);
    };

    return (
        <RadioGroup
            value={
                options.filter((option) => option.isSelected === true)[0]
                    ?.optionId
            }
            onChange={(event) => handleOptionChange(event.target.value)}
        >
            {options.map((option) => (
                <FormControlLabel
                    key={option.optionId}
                    control={<Radio value={option.optionId} />}
                    label={option.optionsName}
                    hidden={option.isHidden}
                />
            ))}
        </RadioGroup>
    );
}

export default SingleSelectRadioButton;
