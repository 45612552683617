import { DayOfWeek } from "../types/enums";

export const getDayNameInGerman = (day: DayOfWeek): string => {
    switch (day) {
        case DayOfWeek.Monday:
            return "Montag";
        case DayOfWeek.Tuesday:
            return "Dienstag";
        case DayOfWeek.Wednesday:
            return "Mittwoch";
        case DayOfWeek.Thursday:
            return "Donnerstag";
        case DayOfWeek.Friday:
            return "Freitag";
        case DayOfWeek.Saturday:
            return "Samstag";
        case DayOfWeek.Sunday:
            return "Sonntag";
    }
};
