import { IOption } from "../model/option";

/** It will generate mapping of all selected options for each particular option
 * With an implicit chain OPTION1ID => OPTION2ID => OPTION3ID)
 *  It will gerenate array like below:
 *  [Option1] => [Option1, Option2, Option3]
 *  [Option2] => [Option2,Option3]
 *  [Option3] => [Option3]
 *  For normal scenario with no chain
 *  [Option1] => [Option1]
 *  [Option2] => [Option2]
 *  [Option3] => [Option3]
 */
export function generateMappingOfAllOptions(
    options: readonly IOption[]
): Readonly<Map<string, readonly string[]>> {
    const optionMappingByOptionId: Map<string, readonly string[]> = new Map<
        string,
        readonly string[]
    >();

    // Create a hash map of options for quick lookup
    const optionsMap: Map<string, IOption> = new Map<string, IOption>();
    options.forEach((option) => {
        optionsMap.set(option.optionId, option);
    });

    // Traverse options to create the mapping
    options.forEach((option) => {
        const allPossibleSelectedOptionsForSelectedOptionId: string[] = [
            option.optionId
        ];
        let currentImplies = option.implies;
        while (currentImplies !== "") {
            const impliedOption = optionsMap.get(currentImplies);
            if (impliedOption) {
                allPossibleSelectedOptionsForSelectedOptionId.push(
                    impliedOption.optionId
                );
                currentImplies = impliedOption.implies;
            } else {
                break;
            }
        }
        optionMappingByOptionId.set(
            option.optionId,
            allPossibleSelectedOptionsForSelectedOptionId
        );
    });
    return optionMappingByOptionId;
}

/** With an implicit chain OPTION1ID => OPTION2ID => OPTION3ID)
 * If Option1 is selected, it will return Option1, Option2, Option3
 * If Option3 is selected, it will return Option3
 *  For normal scenario with no chain
 * If Option1 is selected, it will return Option1
 */
export function findAllActualSelectedOption(
    options: readonly IOption[],
    mappingOfOptionIdsBySingleOptionId: Map<string, readonly string[]>
): readonly string[] {
    // All the options selected from ui
    const allSelectedOptionIdsFromUi = options
        .filter((x) => x.isSelected)
        .map((x) => x.optionId);
    // Find all the selected options due to implicit and normal
    let actuallySelectedOptions: string[] = [];
    allSelectedOptionIdsFromUi.forEach((x) => {
        //If some thing is already selected array then we don't need consider that
        if (!actuallySelectedOptions.includes(x)) {
            const allSelectedOptionsForSingleOption =
                mappingOfOptionIdsBySingleOptionId.get(x) ?? [];
            actuallySelectedOptions = mergeArraysUnique(
                actuallySelectedOptions,
                allSelectedOptionsForSingleOption
            );
        }
    });

    return actuallySelectedOptions;
}

function mergeArraysUnique(
    array1: readonly string[],
    array2: readonly string[]
): string[] {
    const mergedArray = array1.concat(
        array2.filter(function (item) {
            return array1.indexOf(item) === -1;
        })
    );
    return mergedArray;
}
