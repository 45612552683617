export function highlightMatch(name: string, search: string): string[] {
    const index = name.toLowerCase().indexOf(search.toLowerCase());
    if (index === -1) return [name];

    return [
        name.slice(0, index),
        name.slice(index, index + search.length),
        name.slice(index + search.length)
    ];
}
