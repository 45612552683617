import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetSupplierInfo } from "../services/supplierInfoService";
import { BodyContainer, Header, SideNavbar } from "../../../components/ui";
import { useEffect, useState } from "react";
import {
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { isValidUUID } from "../../../utils";
import ApiError from "../../../lib/ApiError";
import { sortListByProductNameAndBookingNumber } from "../utils/SupplierContainer";
import { DemoUrlPrefix } from "../../../constants";

interface Props {
    isFake?: boolean;
}

/**
 * PartneredSupplierContainer is a React component that serves as a container for displaying
 * the interface for partnered suppliers and demo page.
 *
 */
function PartneredSupplierContainer({ isFake }: Readonly<Props>) {
    const location = useLocation();
    const isDemoRoute = location.pathname.startsWith(`/${DemoUrlPrefix}`);
    const { supplierId } = useParams() as { supplierId: string };
    const { bookingId } = useParams() as { bookingId: string };
    const navigate = useNavigate();

    const { data, isError, error, isSuccess } = useGetSupplierInfo(supplierId);
    const [selectedBooking, setSelectedBooking] = useState(bookingId || "");

    const handleBookingDropdownChange = (event: SelectChangeEvent) => {
        const selectedBookingId = event.target.value;
        setSelectedBooking(selectedBookingId);
        //if demo keyword present in url, add it to the url
        navigate(
            `${
                isDemoRoute ? `/${DemoUrlPrefix}` : ""
            }/${supplierId}/booking/${selectedBookingId}`
        );
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        //if supplierId is not valid, navigate to 404 page
        if (!isValidUUID(supplierId)) {
            navigate("/404", { replace: true });
        }
        //if supplierId is valid,
        //navigate to the first booking page if bookingId is not there and api success
        if (isSuccess) {
            if (!bookingId && data.listOfBookings.length > 0) {
                const sortedBookingList = sortListByProductNameAndBookingNumber(
                    data.listOfBookings
                );
                navigate(
                    `${
                        isDemoRoute ? `/${DemoUrlPrefix}` : ""
                    }/${supplierId}/booking/${sortedBookingList[0].bookingId}`,
                    { replace: true }
                );
            }
        }

        if (isError) {
            if (error instanceof ApiError) {
                if (error.status === 404) {
                    navigate("/404", { replace: true });
                }
            }
        }
    }, [isSuccess, isError]);

    // Need this useffect to update the selected booking when url changes
    //(localhost/supplierId/booking to localhost/supplierId/booking/bookingId)
    useEffect(() => {
        if (bookingId) {
            setSelectedBooking(bookingId);
        }
    }, [bookingId]);

    return (
        <>
            <Header
                name={data?.farmerName ?? ""}
                image={data?.farmerImage ?? ""}
                isPartnered={!isFake}
            />
            <BodyContainer>
                <SideNavbar name={data?.supplierName ?? ""}>
                    <h2>Buchung</h2>
                    {data && data.listOfBookings.length > 0 && (
                        <FormControl className="sidebar-dropdown">
                            <Select
                                className="select-text"
                                value={selectedBooking}
                                onChange={handleBookingDropdownChange}
                            >
                                <MenuItem disabled className="underlined">
                                    Buchung wählen
                                </MenuItem>
                                {sortListByProductNameAndBookingNumber(
                                    data.listOfBookings
                                ).map((item) => (
                                    <MenuItem
                                        key={item.bookingId}
                                        value={item.bookingId}
                                    >
                                        {item.productName} |{" "}
                                        {item.bookingNumber}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </SideNavbar>
            </BodyContainer>
        </>
    );
}

export default PartneredSupplierContainer;
