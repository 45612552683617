import { useQuery } from "@tanstack/react-query";
import { get } from "../../../lib";
import { IBookingDetail } from "./dtos";
import { getGetBookingPotentialsInfo } from "../../../constants/reactQueryKey";

export const useGetBookingInfo = (bookingId: string) => {
    return useQuery([getGetBookingPotentialsInfo, bookingId], async () => {
        return await get<IBookingDetail>(
            `/bookings/${bookingId}/potentialsInfo`
        );
    });
};
