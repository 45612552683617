export interface IAreaInformation {
    areaId: string;
    name: string;
    totalNumberOfZipCode: number;
    zipCodeList: string[];
}

export enum ZipCodeAreaType {
    City = "City",
    District = "District",
    State = "State"
}
