import { BookingPotentialCategory, DayOfWeek } from "../../../../types/enums";
import { IProductCriteria, IWeekDayTime } from "../../types/interfaces";
import { CriteriaInputType, ViewType } from "../../types/criteriaGroup/enums";
import { IBookingPotentialMatchingInformationForCriteria } from "../../types/criteriaGroup/interfaces";

export function getCriteriaViewType(criteria: IProductCriteria): ViewType {
    if (criteria.inputType === CriteriaInputType.Slider) {
        return ViewType.Slider;
    } else {
        if (criteria.optionConfiguration) {
            if (criteria.optionConfiguration.representAsList === true) {
                return ViewType.MultiSelectDropdown;
            } else if (
                criteria.optionConfiguration.multipleValuesAllowed === true
            ) {
                let supplyImplies = criteria.optionConfiguration.options.some(
                    (option) => option.implies
                );
                return supplyImplies
                    ? ViewType.SingleSelectRadioButton
                    : ViewType.MultiSelectCheckBox;
            }
        }
        return ViewType.SingleSelectRadioButton;
    }
}

export function calculateGeneralCriteriaPotential(
    bookingPotentials: IBookingPotentialMatchingInformationForCriteria[],
    isEmailAddressRequired: boolean,
    isPostalAddressRequired: boolean,
    noCrossSelling: boolean
): number {
    let potentialForEmailVerified = bookingPotentials.find(
        (x) => x.category === BookingPotentialCategory.EmailVerified
    );
    let potentialForValidAddress = bookingPotentials.find(
        (x) => x.category === BookingPotentialCategory.PostalAddressRequired
    );
    let potentialForNoCrossSelling = bookingPotentials.find(
        (x) => x.category === BookingPotentialCategory.NoCrossSelling
    );

    let finalGeneralCriteriaPotential: number =
        (isEmailAddressRequired && potentialForEmailVerified
            ? potentialForEmailVerified.percentage
            : 1) *
        (isPostalAddressRequired && potentialForValidAddress
            ? potentialForValidAddress.percentage
            : 1) *
        (noCrossSelling && potentialForNoCrossSelling
            ? potentialForNoCrossSelling.percentage
            : 1);

    return finalGeneralCriteriaPotential;
}

export function calculateWeekdayPotential(
    bookingPotentials: IBookingPotentialMatchingInformationForCriteria[],
    weekdays: IWeekDayTime[]
): number {
    const allowedWeekdays = weekdays
        .filter((weekday) => weekday.isContactOnThisDayAllowed)
        .map((weekday) => weekday.dayOfWeek);
    // If all 7 week days are allowed then week day potential is 100%, we don't need to calculate it.
    let finalWeekdayPotential: number = 1;

    if (allowedWeekdays.length < 7) {
        // If total number of allowed week days is less than 7, it means there are some days which are not allowed
        // as a result we need to calculate week day potential.
        finalWeekdayPotential = bookingPotentials
            .filter(
                (bp) =>
                    bp.category === BookingPotentialCategory.WeekDay &&
                    allowedWeekdays.some(
                        (weekDay) => DayOfWeek[weekDay] === bp.value
                    )
            )
            .reduce((sum, current) => sum + current.percentage, 0);
    }

    return finalWeekdayPotential;
}
