import React, { useCallback, useState } from "react";
import { BookingAreaType } from "../types/enums";
import { Box, Typography } from "@mui/material";
import BookingSingleZipCodeGroup from "./BookingSingleZipCodeGroup";
import BookingZipCodeActiveDetails from "./BookingZipCodeActiveDetails";
import {
    calculateBookingZipCodePotential,
    getUniqueZipCodesFromPostalAreaArray as getSelectedUniqueZipcodesFromBookingAreaInformations
} from "../utils/bookingZipCodeOverview";
import "../../../assets/styles/booking/BookingZipCodeOverview.scss";
import { IBookingPotentialMatchingInformation } from "../types/interfaces";
import { ZipCodeModalContainer } from "../../zipCode";
import BookingChangesHint from "./BookingChangesHint";

export interface IBookingAreaInformation {
    postalCodeAreaId: string;
    name: string;
    totalNumberOfZipCode: number;
    zipCodeList: IZipCodeList[];
    isSelected: boolean;
}

export interface IZipCodeList {
    zipCode: string;
}

interface Props {
    bookingAreaInformations: IBookingAreaInformation[];
    bookingId: string;
    bookingZipCodePotentialInfos: IBookingPotentialMatchingInformation[];
    getPotentialNumberOnBookingZipCodeSelectionChange: (
        potential: number
    ) => void;
}

interface BookingZipCodeOverviewState {
    isNationwide: boolean;
    totalNumberOfZipCodes: number;
    companyAreas: BookingAreaInformationWithDetails[];
}

interface BookingAreaInformationWithDetails extends IBookingAreaInformation {
    zipCodeDetails: string;
}

/**
 * BookingZipCodeOverview is a React component that provides a detailed overview of target areas
 * for bookings by managing zip code groups and displaying their potential impacts on market share.
 *
 * The component integrates multiple functionalities:
 * - Displays the current zip code targets and allows updates through a detailed and interactive interface.
 * - Supports adding new zip code groups and recalculates potential impacts dynamically upon any interaction.
 */
function BookingZipCodeOverview({
    bookingAreaInformations,
    bookingId,
    bookingZipCodePotentialInfos,
    getPotentialNumberOnBookingZipCodeSelectionChange
}: Readonly<Props>) {
    const totalZipCodesInGermany = 8313;
    const [bookingZipCodeOverviewState, setBookingZipCodeOverviewState] =
        useState<BookingZipCodeOverviewState>(
            initializeBookingZipCodeOverviewState()
        );

    function initializeBookingZipCodeOverviewState(): BookingZipCodeOverviewState {
        const isNationWide = !bookingAreaInformations.some((x) => x.isSelected);
        const totalNumberOfZipCodes =
            getSelectedUniqueZipcodesFromBookingAreaInformations(
                bookingAreaInformations
            )[1];
        const areaInfos = bookingAreaInformations.map((info) => {
            return {
                ...info,
                zipCodeDetails: info.zipCodeList
                    .map((zip) => zip.zipCode)
                    .join(", ")
            };
        });
        return {
            isNationwide: isNationWide,
            totalNumberOfZipCodes: isNationWide
                ? totalZipCodesInGermany
                : totalNumberOfZipCodes,
            companyAreas: areaInfos
        };
    }

    const zipCodeGroupSelectionChange = useCallback(
        (checked: boolean, postalAreaId: string) => {
            setBookingZipCodeOverviewState((prevData) => {
                let isNationwide = prevData.isNationwide;
                let totalUniqueZipCodes: Set<string> = new Set();
                let totalNumberOfZipCodes = 0;
                let companyAreas: BookingAreaInformationWithDetails[] = [];

                if (postalAreaId === BookingAreaType.Nationwide) {
                    if (checked) {
                        isNationwide = true;
                        totalNumberOfZipCodes = totalZipCodesInGermany;
                    } else {
                        isNationwide = false;
                        [totalUniqueZipCodes, totalNumberOfZipCodes] =
                            getSelectedUniqueZipcodesFromBookingAreaInformations(
                                prevData.companyAreas
                            );
                    }
                    companyAreas = prevData.companyAreas.map((info) => {
                        return info;
                    });
                } else {
                    companyAreas = prevData.companyAreas.map((info) => {
                        if (info.postalCodeAreaId === postalAreaId) {
                            return {
                                ...info,
                                isSelected: checked
                            };
                        }
                        return info;
                    });
                    [totalUniqueZipCodes, totalNumberOfZipCodes] =
                        getSelectedUniqueZipcodesFromBookingAreaInformations(
                            companyAreas
                        );
                }

                getPotentialNumberOnBookingZipCodeSelectionChange(
                    calculateBookingZipCodePotential(
                        bookingZipCodePotentialInfos,
                        Array.from(totalUniqueZipCodes),
                        isNationwide
                    )
                );

                return {
                    isNationwide: isNationwide,
                    totalNumberOfZipCodes: totalNumberOfZipCodes,
                    companyAreas: companyAreas
                };
            });
        },
        []
    );

    function getCheckBoxStateForBookingAreaGroup(
        isSelected: boolean,
        isNationWide: boolean
    ): boolean {
        if (!isSelected) return false;
        return !isNationWide;
    }

    function addZipCodeGroupOnSuccess(
        areaId: string,
        name: string,
        totalNumberOfZipCode: number,
        zipCodeList: string[]
    ) {
        const newBookingArea: BookingAreaInformationWithDetails = {
            postalCodeAreaId: areaId,
            name: name,
            totalNumberOfZipCode: totalNumberOfZipCode,
            zipCodeList: zipCodeList.map((zipCode) => ({ zipCode })),
            isSelected: true,
            zipCodeDetails: zipCodeList.join(", ")
        };

        setBookingZipCodeOverviewState((prevData) => {
            const updatedBookingAreaInformations = [
                newBookingArea,
                ...prevData.companyAreas
            ];

            const [totalUniqueZipCodes, totalNumberOfZipCodes] =
                getSelectedUniqueZipcodesFromBookingAreaInformations(
                    updatedBookingAreaInformations
                );

            getPotentialNumberOnBookingZipCodeSelectionChange(
                calculateBookingZipCodePotential(
                    bookingZipCodePotentialInfos,
                    Array.from(totalUniqueZipCodes),
                    prevData.isNationwide
                )
            );

            return {
                ...prevData,
                totalNumberOfZipCodes: totalNumberOfZipCodes,
                companyAreas: updatedBookingAreaInformations
            };
        });
    }

    return (
        <>
            <h2>
                Prüfen Sie den Einfluss Ihres Zielgebiets auf Ihren Marktanteil
            </h2>
            <BookingChangesHint />
            <Box className="zipcode-container">
                <Typography mb={3} className="zipcode-heading">
                    Aktuelles Zielgebiet
                </Typography>
                <BookingZipCodeActiveDetails
                    count={bookingZipCodeOverviewState.totalNumberOfZipCodes}
                    isNationWide={bookingZipCodeOverviewState.isNationwide}
                >
                    <div>
                        {Array.from(
                            new Set(
                                bookingZipCodeOverviewState.companyAreas
                                    .filter((info) => info.isSelected)
                                    .flatMap((info) =>
                                        info.zipCodeList.map(
                                            (zip) => zip.zipCode
                                        )
                                    )
                            )
                        ).join(", ")}
                    </div>
                </BookingZipCodeActiveDetails>
                <div className="accordion-heading zipcode-subheading pr-10">
                    <Typography>Gebietsgruppe</Typography>
                    <Typography className="hidden-mobile">
                        Anzahl PLZ
                    </Typography>
                    <Typography>aktiv/pausiert</Typography>
                </div>
                <BookingSingleZipCodeGroup
                    title="Bundesweit"
                    count={totalZipCodesInGermany}
                    onCheckboxChanged={zipCodeGroupSelectionChange}
                    areaId={BookingAreaType.Nationwide}
                    checkboxDisabled={false}
                    checkboxChecked={bookingZipCodeOverviewState.isNationwide}
                />
                {bookingZipCodeOverviewState.companyAreas.map((info) => (
                    <BookingSingleZipCodeGroup
                        key={`${info.postalCodeAreaId}-${bookingId}`}
                        title={info.name}
                        count={info.totalNumberOfZipCode}
                        onCheckboxChanged={zipCodeGroupSelectionChange}
                        areaId={info.postalCodeAreaId}
                        zipCodeDetails={info.zipCodeDetails}
                        checkboxChecked={getCheckBoxStateForBookingAreaGroup(
                            info.isSelected,
                            bookingZipCodeOverviewState.isNationwide
                        )}
                        checkboxDisabled={
                            bookingZipCodeOverviewState.isNationwide
                        }
                    />
                ))}
            </Box>
            <ZipCodeModalContainer
                addZipCodeGroupOnSuccess={addZipCodeGroupOnSuccess}
            />
        </>
    );
}

export default React.memo(BookingZipCodeOverview);
