import { Fragment, useEffect, useRef } from "react";
import {
    IProductCriteriaService,
    PrecalculatedCriteriaMatching,
    createProductCriteriaService
} from "../../../lib";
import {
    IGeneralCriteria,
    IProductCriteriaGroup,
    IProductCriteriaOption
} from "../types/interfaces";
import { convertGroupToProductServiceCriterias } from "../utils/criteriaGroup/mapper";
import {
    getCriteriaViewType,
    calculateGeneralCriteriaPotential,
    calculateWeekdayPotential
} from "../utils/criteriaGroup";
import MultiSelectCheckBox from "./MultiSelectCheckBox";
import SingleSelectRadioButton from "./SingleSelectRadioButton";
import GeneralCriteria from "./GeneralCriteria";
import CriteriaSlider from "./CriteriaSlider";
import MultiSelectDropdown from "./MultiSelectDropdown";
import "../../../assets/styles/criteria/CriteriaGroup.scss";
import "../../../assets/styles/criteria/CriteriaContainer.scss";
import { ViewType } from "../types/criteriaGroup/enums";
import { IBookingPotentialMatchingInformationForCriteria } from "../types/criteriaGroup/interfaces";

interface Props {
    bookingId: string;
    productCriteriaGroups: IProductCriteriaGroup[];
    calculatedCriteriaMatching: PrecalculatedCriteriaMatching;
    getPotentialNumberOnProductCriteriaValueChange?: (count: number) => void;
    generalCriteria?: IGeneralCriteria;
    generalCriteriaPotentialInfos?: IBookingPotentialMatchingInformationForCriteria[];
    getPotentialNumberOnGeneralCriteriaValueChange?: (
        generalPotential: number,
        weekdayPontential: number
    ) => void;
}

/**
 * CriteriaGroup is a React component responsible for rendering and managing a set of criteria groups
 * related to product options or general booking criteria. It handles dynamic interactions, enabling the
 * adjustment and calculation of potential impacts based on selected criteria.
 *
 * The component integrates complex logic for managing both product criteria and general criteria, leveraging
 * state and references to handle updates efficiently and to prevent unnecessary re-renders.
 *
 *  Utilizes React's useRef to maintain a mutable reference to the criteria service without triggering re-renders.
 *  Employs useCallback to handle changes efficiently, reducing the overhead of frequent updates.
 *
 */
function CriteriaGroup({
    bookingId,
    productCriteriaGroups,
    calculatedCriteriaMatching,
    getPotentialNumberOnProductCriteriaValueChange,
    generalCriteria,
    generalCriteriaPotentialInfos,
    getPotentialNumberOnGeneralCriteriaValueChange
}: Readonly<Props>) {
    const productCriteriaServiceRef = useRef<IProductCriteriaService>();

    // eslint-disable-next-line
    // dont use productCriteriaGroups, calculatedCriteriaMatching
    // as it is complex object creates unnecessary rendering
    useEffect(() => {
        productCriteriaServiceRef.current = createProductCriteriaService(
            convertGroupToProductServiceCriterias(productCriteriaGroups),
            calculatedCriteriaMatching
        );
    }, [bookingId]);

    function filteredProductCriteriaGroup(
        productCriteriaGroups: IProductCriteriaGroup[]
    ): IProductCriteriaGroup[] {
        return productCriteriaGroups?.filter((criteriaGroup) =>
            criteriaGroup.criteriaList.some(
                (criteria) => criteria.isHidden === false
            )
        );
    }

    const generalCriterionOnChange = (generalCriteria: IGeneralCriteria) => {
        if (!generalCriteriaPotentialInfos) return;
        const generalPotential = calculateGeneralCriteriaPotential(
            generalCriteriaPotentialInfos,
            generalCriteria.isRequestEmailRequired,
            generalCriteria.isRequestValidAddressRequired,
            generalCriteria.noCrossSelling
        );
        const weekDayPotential = calculateWeekdayPotential(
            generalCriteriaPotentialInfos,
            generalCriteria.weekDayTimes
        );
        getPotentialNumberOnGeneralCriteriaValueChange?.(
            generalPotential,
            weekDayPotential
        );
    };

    const optionOnClick = (
        criteriaId: string,
        mitOptions: IProductCriteriaOption[]
    ) => {
        if (!productCriteriaServiceRef.current) return;
        const optionConfiguration =
            productCriteriaServiceRef.current.getCriteriaById(
                criteriaId
            ).optionConfiguration;
        const mitOptionsMap = new Map(
            mitOptions.map((option) => [option.optionId, option])
        );

        optionConfiguration.options.forEach((option) => {
            const mitOption = mitOptionsMap.get(option.optionId);
            if (mitOption) {
                option.isSelected = mitOption.isSelected;
            }
        });
        optionConfiguration.handleChange();
        getPotentialNumberOnProductCriteriaValueChange?.(
            productCriteriaServiceRef.current.productCriteriaPotential
        );
    };

    const sliderOnChange = (criteriaId: string, minMax: [number, number]) => {
        if (!productCriteriaServiceRef.current) return;
        const sliderConfiguration =
            productCriteriaServiceRef.current.getCriteriaById(
                criteriaId
            ).sliderConfiguration;
        sliderConfiguration.min = minMax[0];
        sliderConfiguration.max = minMax[1];
        sliderConfiguration.handleChange();
        getPotentialNumberOnProductCriteriaValueChange?.(
            productCriteriaServiceRef.current.productCriteriaPotential
        );
    };

    return (
        <div className="criteria-group-container">
            {generalCriteria && (
                <GeneralCriteria
                    onChangeGeneralCriteria={generalCriterionOnChange}
                    isRequestEmailRequired={
                        generalCriteria.isRequestEmailRequired
                    }
                    isRequestValidAddressRequired={
                        generalCriteria.isRequestValidAddressRequired
                    }
                    noCrossSelling={generalCriteria.noCrossSelling}
                    weekDayTimes={generalCriteria.weekDayTimes}
                />
            )}
            {filteredProductCriteriaGroup(productCriteriaGroups).map(
                (criteriaGroup, index) => (
                    <div
                        className="criteria-contaner"
                        key={`container-${index + bookingId}`}
                    >
                        <div
                            className="criteria-header"
                            key={`header-${index + bookingId}`}
                        >
                            <h3>{criteriaGroup.groupName}</h3>
                        </div>
                        <div
                            className="criteria-body"
                            key={`body-${index + bookingId}`}
                        >
                            {criteriaGroup.criteriaList
                                .filter(
                                    (criteria) => criteria.isHidden === false
                                )
                                .map((criteria) => {
                                    // Determine the component to render based on the criteriaViewType
                                    let component;
                                    if (
                                        getCriteriaViewType(criteria) ===
                                            ViewType.Slider &&
                                        criteria.sliderConfiguration
                                    ) {
                                        component = (
                                            <CriteriaSlider
                                                criteriaId={criteria.criteriaId}
                                                sliderConfiguration={
                                                    criteria.sliderConfiguration
                                                }
                                                key={criteria.criteriaId}
                                                onChange={sliderOnChange}
                                            />
                                        );
                                    } else if (
                                        getCriteriaViewType(criteria) ===
                                            ViewType.MultiSelectCheckBox &&
                                        criteria.optionConfiguration
                                    ) {
                                        component = (
                                            <MultiSelectCheckBox
                                                criteriaId={criteria.criteriaId}
                                                optionConfiguration={
                                                    criteria.optionConfiguration
                                                }
                                                key={criteria.criteriaId}
                                                onClick={optionOnClick}
                                            />
                                        );
                                    } else if (
                                        getCriteriaViewType(criteria) ===
                                            ViewType.MultiSelectDropdown &&
                                        criteria.optionConfiguration
                                    ) {
                                        component = (
                                            <MultiSelectDropdown
                                                criteriaId={criteria.criteriaId}
                                                optionConfiguration={
                                                    criteria.optionConfiguration
                                                }
                                                key={criteria.criteriaId}
                                                onClick={optionOnClick}
                                            />
                                        );
                                    } else if (criteria.optionConfiguration) {
                                        component = (
                                            <SingleSelectRadioButton
                                                criteriaId={criteria.criteriaId}
                                                optionConfiguration={
                                                    criteria.optionConfiguration
                                                }
                                                key={criteria.criteriaId}
                                                onClick={optionOnClick}
                                            />
                                        );
                                    }

                                    return (
                                        <Fragment key={criteria.criteriaId}>
                                            <p className="highlight-blue">
                                                {criteria.criteriaName}
                                            </p>
                                            {criteria.info && (
                                                <p className="criteria-info">
                                                    {criteria.info}
                                                </p>
                                            )}
                                            {component}
                                        </Fragment>
                                    );
                                })}
                        </div>
                    </div>
                )
            )}
        </div>
    );
}

export default CriteriaGroup;
