export function deepFreeze<T>(obj: T): Readonly<T> {
    if (obj === null || typeof obj !== "object") {
        return obj;
    }

    if (Object.isFrozen(obj)) {
        return obj;
    }

    Object.freeze(obj);

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            deepFreeze(obj[key]);
        }
    }

    return obj;
}
