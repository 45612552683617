/**
 * @enum OperatorRule
 * Represents different rules used for option criteria.
 * @property {number} And_Requests - All selected options on the request side must also be selected on the booking side.
 * @property {number} Or - There must be only one matching option.
 * @property {number} And_Bookings - All selected options on the booking side must also be selected on the request side.
 */
export enum OperatorRule {
    And_Requests = 0,
    Or = 1,
    And_Bookings = 2
}
