import {
    CriteriaIdMap,
    OptionResults,
    PrecalculatedCriteriaMatching,
    SliderResults
} from "../../../../lib";
import {
    IProductCriteria,
    IProductCriteriaGroup,
    IGeneralCriteria,
    IBookingPotentialMatchingInformationForCriteria
} from "../../../criteria";
import { IBookingPotentialMatchingInformation } from "../../types/interfaces";
import { BookingPotentialCategory } from "../../../../types/enums";
import {
    IBookingInfo,
    ICriteriaGroupList,
    IProductCriteriaMatchingInformations
} from "../../services/dtos";

export function convertProductCriteriaDataToPropsData(
    criteriaGroups: ICriteriaGroupList[]
): IProductCriteriaGroup[] {
    return criteriaGroups.map((criteriaGroup) => {
        const convertedCriteriaList: IProductCriteria[] =
            criteriaGroup.criteriaList.map((criteria) => {
                let sliderConfig = null;
                if (criteria.sliderConfiguration) {
                    const selectedValues =
                        criteria.sliderConfiguration.selectedValue
                            .replace("[", "")
                            .replace("]", "")
                            .split(",")
                            .map(Number);

                    sliderConfig = {
                        ...criteria.sliderConfiguration,
                        min: selectedValues[0],
                        max: selectedValues[1]
                    };
                }

                let optionConfig = null;
                if (criteria.optionConfiguration) {
                    optionConfig = {
                        ...criteria.optionConfiguration,
                        options: criteria.optionConfiguration.options.map(
                            (option) => ({
                                optionsName: option.optionsName,
                                optionId: option.optionId,
                                implies: option.implies,
                                isHidden: option.isHidden,
                                isSelected: option.isSelected
                            })
                        )
                    };
                }

                return {
                    criteriaName: criteria.criteriaName,
                    info: criteria.info,
                    criteriaId: criteria.criteriaId,
                    inputType: criteria.inputType,
                    isHidden: criteria.isHidden,
                    sliderConfiguration: sliderConfig,
                    optionConfiguration: optionConfig
                };
            });

        return {
            groupName: criteriaGroup.groupName,
            groupOrderIndex: criteriaGroup.groupOrderIndex,
            criteriaList: convertedCriteriaList
        };
    });
}

export function convertToGeneralCriteriaPropsData(
    generalBookingInfo: IBookingInfo
): IGeneralCriteria {
    return {
        isRequestEmailRequired: generalBookingInfo.isRequestEmailRequired,
        isRequestValidAddressRequired:
            generalBookingInfo.isRequestValidAddressRequired,
        noCrossSelling: generalBookingInfo.noCrossSelling,
        weekDayTimes: generalBookingInfo.weekDayTimeDtos
    };
}

export function convertBookingPotentialsToGeneralCriteriaPotentialPropsData(
    bookingPotentialMatchingInfomations: IBookingPotentialMatchingInformation[]
): IBookingPotentialMatchingInformationForCriteria[] {
    return bookingPotentialMatchingInfomations
        .filter(
            (x) =>
                x.category === BookingPotentialCategory.WeekDay ||
                x.category === BookingPotentialCategory.EmailVerified ||
                x.category === BookingPotentialCategory.PostalAddressRequired ||
                x.category === BookingPotentialCategory.NoCrossSelling
        )
        .map((bookingPotentialMatchingInfomation) => ({
            category: bookingPotentialMatchingInfomation.category,
            value: bookingPotentialMatchingInfomation.value,
            percentage: bookingPotentialMatchingInfomation.percentage
        }));
}

export function convertBookingPotentialsToPricePotentialPropsData(
    bookingPotentialMatchingInfomations: IBookingPotentialMatchingInformation[]
): IBookingPotentialMatchingInformation[] {
    return bookingPotentialMatchingInfomations
        .filter(
            (x) =>
                x.category === BookingPotentialCategory.LeadPrice1 ||
                x.category === BookingPotentialCategory.LeadPrice2 ||
                x.category === BookingPotentialCategory.LeadPrice3
        )
        .map((bookingPotentialMatchingInfomation) => ({
            category: bookingPotentialMatchingInfomation.category,
            value: bookingPotentialMatchingInfomation.value,
            percentage: bookingPotentialMatchingInfomation.percentage
        }));
}

export function convertBookingPotentialsToZipCodePotentialPropsData(
    bookingPotentialMatchingInfomations: IBookingPotentialMatchingInformation[]
): IBookingPotentialMatchingInformation[] {
    return bookingPotentialMatchingInfomations
        .filter((x) => x.category === BookingPotentialCategory.PostalCode)
        .map((bookingPotentialMatchingInfomation) => ({
            category: bookingPotentialMatchingInfomation.category,
            value: bookingPotentialMatchingInfomation.value,
            percentage: bookingPotentialMatchingInfomation.percentage
        }));
}

export function convertProductCriteriaMatchingInfoToPropsData(
    productCriteriaMatchingInfo: IProductCriteriaMatchingInformations
): PrecalculatedCriteriaMatching {
    const optionScenarioResults: CriteriaIdMap<OptionResults> = {};
    const sliderScenarioResults: CriteriaIdMap<SliderResults> = {};

    for (const criteriaId in productCriteriaMatchingInfo.scenarioResults) {
        const result = productCriteriaMatchingInfo.scenarioResults[criteriaId];
        // Check if the 'result' variable is an object and not null. This is to ensure that the 'result' is a valid object that can be processed.
        if (
            typeof result === "object" &&
            result !== null &&
            // Iterate over the values of the 'result' object.
            // If the condition is met, treat 'result' as an instance of 'OptionResults' and store it in the 'optionScenarioResults' object under the 'criteriaId' key.
            // This implies that 'result' is being classified into two types based on its structure: 'OptionResults' or 'SliderResults'.
            Object.values(result).some(
                (value) => "True" in value && "False" in value
            )
        ) {
            optionScenarioResults[criteriaId] = result as OptionResults;
        } else {
            sliderScenarioResults[criteriaId] = result as SliderResults;
        }
    }

    return {
        criteriaFulFilled: productCriteriaMatchingInfo.criteriaFulFilled,
        countings: productCriteriaMatchingInfo.countings as CriteriaIdMap<
            number[]
        >,
        optionScenarioResults: optionScenarioResults,
        sliderScenarioResults: sliderScenarioResults,
        combinations: {}
    };
}
