import { useState, useEffect } from 'react';
import "../../assets/styles/components/BackToTopButton.scss"

/**
 *
 * BackToTopButton is a React component that creates a "Back to Top" button which becomes visible when the user scrolls down a specified amount on a webpage.
 * It provides a convenient way for users to return to the top of the page without manually scrolling. The visibility and
 * functionality of the button are managed using React state and browser event listeners.
 *
 */
const BackToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        });
    }, []);

    const goTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const className = isVisible ?  'shown': 'hidden';

    return (
        <button className={`back-to-top ${ className }`}  onClick={goTop}>
            { /* Adding SVG icon inline, to be able to change its color on hover */ }            
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="back-to-top-icon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="M7 11l5-5m0 0l5 5m-5-5v12"
                />
            </svg>

        </button>
    );
}

export default BackToTopButton;