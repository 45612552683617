import { Navigate, Route } from "react-router-dom";
import { PartneredSupplierContainer } from "../../features/supplierInfo";
import { DemoUrlPrefix } from "../../constants";
import {
    BookingDetailsContainer,
    BookingIntroduction
} from "../../features/booking";

const nestedRoutes = (
    <>
        <Route index element={<Navigate to="booking" replace />} />
        <Route path="booking">
            <Route index element={<BookingIntroduction />} />
            <Route path=":bookingId" element={<BookingDetailsContainer />} />
        </Route>
    </>
);

export const BookingRoutes = (
    <>
        <Route path=":supplierId" element={<PartneredSupplierContainer />}>
            {nestedRoutes}
        </Route>
        <Route
            path={`/${DemoUrlPrefix}/:supplierId`}
            element={<PartneredSupplierContainer isFake={true} />}
        >
            {nestedRoutes}
        </Route>
    </>
);
