import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import "../../assets/styles/components/LoadingSpinner.scss";
import {
    getGetAreasBySearch,
    getGetPostCodeValid
} from "../../constants/reactQueryKey";

/**
 *
 * LoadingSpinner is a React component that provides visual feedback for network activity in our application. It displays a loading
 * spinner icon whenever there are ongoing data fetching or mutating operations, except for specified exceptions. This
 * ensures users are aware that data processing or updates are taking place in the background.
 */
function LoadingSpinner() {
    const notLoadingSpinnerUrls = [getGetAreasBySearch, getGetPostCodeValid];
    const isFetching = useIsFetching({
        predicate: (query) =>
            // Exclude a specific query key or multiple keys by returning false for them
            typeof query.queryKey[0] === "string" &&
            !notLoadingSpinnerUrls.includes(query.queryKey[0])
    });

    const isMutating = useIsMutating(); // Get the number of ongoing mutations
    // Combine fetching and mutating states to determine overall network activity
    const isLoading = isFetching > 0 || isMutating > 0;
    return (
        <div
            className={`loading-spinner ${
                isLoading ? "loading-spinner-show" : ""
            }`}
        >
            <div className="icons-wait loading-spinner-icon "></div>
        </div>
    );
}

export default LoadingSpinner;
