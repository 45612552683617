import { useEffect } from "react";
import ApiError from "../lib/ApiError";
import { toast } from "react-toastify";
import { ReactQueryErrorHandler } from "../lib";
import { getGetSupplierInfo } from "../constants/reactQueryKey";
import { trackException } from "../lib/appInsightsFactory";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
function useErrorHandler() {
    useEffect(() => {
        // Handle errors
        const handleError = (event: ErrorEvent) => {
            if (!(event.error instanceof ApiError)) {
                if (process.env.NODE_ENV === "development") {
                    console.log("ApiError caught:", event);
                } else {
                    toast.error("Ein unerwarteter Fehler ist aufgetreten");
                }
                trackException(event.error, SeverityLevel.Error);
            }
            event.preventDefault();
        };

        // Add event listeners
        window.addEventListener("error", handleError);

        // Clean up event listeners
        return () => {
            window.removeEventListener("error", handleError);
        };
    }, []);

    const customReactQueryErrorHandler: ReactQueryErrorHandler = (
        error,
        query
    ) => {
        if (error instanceof ApiError) {
            if (
                typeof query.queryKey[0] === "string" &&
                [getGetSupplierInfo].includes(query.queryKey[0])
            ) {
                if (error.status === 500 || error.status === 503) {
                    //We are not using useNavigate here
                    //Because it creates isse with react-query
                    window.location.href = "/500";
                }
            }
        }
    };

    return { customReactQueryErrorHandler };
}

export default useErrorHandler;
