import { Box, Paper, Collapse } from "@mui/material";
import { useState } from "react";

interface Props {
    title?: string;
    type?: string;
    zipCodeList: string[];
}

/**
 *
 * ZipCodeModalContainer is a React component that displays detailed information about a specific search result related to zip codes.
 * It allows users to view the title, type, and a list of zip codes associated with the result.
 */
function ZipCodeSearchResultDetails({
    title,
    type,
    zipCodeList
}: Readonly<Props>) {
    const [isExpanded, setIsExpanded] = useState(isExpandedByDefault());
    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    function isExpandedByDefault(): boolean {
        return zipCodeList.length <= 10;
    }

    return (
        <Paper className="mt-20">
            {title && (
                <div>
                    <strong>{title}</strong>
                </div>
            )}
            {type && <div>{type}</div>}
            <div
                className={isExpandedByDefault() ? "" : "pointer"}
                onClick={isExpandedByDefault() ? () => {} : handleToggleExpand}
            >
                Postleitzahlen: {zipCodeList.length}
            </div>
            <Collapse in={isExpanded}>
                <Box component="div" className="zipcodes">
                    {zipCodeList.join(", ")}
                </Box>
            </Collapse>
        </Paper>
    );
}

export default ZipCodeSearchResultDetails;
