import { Button } from "@mui/material";

interface Props {
    zipCodeGroupAddClick: () => void;
    modalCloseButtonClick: () => void;
    disabled: boolean;
}

/**
 *
 * ZipCodeModalActions is a React component that provides user interface elements for managing zip code groups within a modal dialogue.
 * It features two primary actions: adding a new zip code group and closing the modal.
 *
 */
function ZipCodeModalActions({
    zipCodeGroupAddClick,
    modalCloseButtonClick,
    disabled
}: Readonly<Props>) {
    return (
        <div className="button-group end">
            <Button
                variant="contained"
                color="secondary"
                onClick={modalCloseButtonClick}
            >
                Abbrechen
            </Button>
            <Button
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={zipCodeGroupAddClick}
            >
                + Zielgebiet hinzufügen
            </Button>
        </div>
    );
}

export default ZipCodeModalActions;
