function LeadReceiptConfirmationMessage() {
    return (
        <div className="confirmation-message flex">
            <div className="icon-waiting hidden-mobile"></div>
            <div>
                <h2 className="mt-10 mb-10">Ihre Kontaktdaten werden aktualisiert</h2>
                <p>
                    Bitte beachten Sie, dass die endgültige Aktivierung mehrere
                    Tage in Anspruch nehmen kann.
                </p>
            </div>
        </div>
    );
}

export default LeadReceiptConfirmationMessage;
