import { EffectiveLeadNettoPriceAdjustmentState } from "../types/enums";

export namespace EffectiveLeadNettoPriceAdjustmentStateExtensions {
    export function toFactor(state: EffectiveLeadNettoPriceAdjustmentState): number {
      switch (state) {
        case EffectiveLeadNettoPriceAdjustmentState.DecreasedByInkasso:
          return 0.5;
        case EffectiveLeadNettoPriceAdjustmentState.Normal:
          return 1;
        default:
          return 1;
      }
    }
  }
