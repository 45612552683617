import { CountryCode } from "libphonenumber-js";
import parsePhoneNumber from "libphonenumber-js/max";
import { GermanCountryIso2 } from "../constants";

export const isValidPhoneNumber = (
    value: string,
    countryIso2: CountryCode = GermanCountryIso2
): boolean => {
    try {
        const phoneNumber = parsePhoneNumber(value, countryIso2);
        if (phoneNumber) {
            return phoneNumber.isValid();
        }
        return false;
    } catch (error) {
        return false;
    }
};
