// Import Statements
import axios, { AxiosInstance } from "axios";
import {
    BACKENDURL,
    PflegeHilfeAuthInformation
} from "../constants/pflgehilfeData";
import ApiError from "./ApiError";

// Create API Client
const createApiClient = (baseUrl: string, auth?: any): AxiosInstance => {
    return axios.create({
        baseURL: baseUrl,
        auth: auth
    });
};

// Error Checking
const errorCheck = (error: any): ApiError => {
    if (axios.isAxiosError(error)) {
        let errorMessage = error.message;
        const response = error.response;
        if (axios.isCancel(error)) {
            return new ApiError("Request cancelled by user", 0);
        } else if (response) {
            return new ApiError(errorMessage, response.status, response.data);
        }
    }
    return new ApiError("An unknown error occurred", 503);
};

// ========== Core API Methods ==========

// Generic Request Function
const request = async <T>(
    method: "get" | "post" | "put" | "delete" | "patch",
    relativePath: string,
    data?: any,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => {
    try {
        const response = await apiClient.request<T>({
            method,
            url: relativePath,
            data
        });
        const responseData: T = response.data;
        return responseData;
    } catch (error) {
        throw errorCheck(error);
    }
};

// GET Request
export const get = <T>(
    relativePath: string,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => request("get", relativePath, undefined, apiClient);

// POST Request
export const post = <T>(
    relativePath: string,
    data?: any,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => request("post", relativePath, data, apiClient);

// PUT Request
export const put = <T>(
    relativePath: string,
    data?: any,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => request("put", relativePath, data, apiClient);

export const patch = <T>(
    relativePath: string,
    data?: any,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => request("patch", relativePath, data, apiClient);

// DELETE Request
export const del = <T>(
    relativePath: string,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => request("delete", relativePath, undefined, apiClient);

// ========== Default API Client Creation ==========
const PflgeApiClient = createApiClient(BACKENDURL, PflegeHilfeAuthInformation);
