import { useCallback, useMemo } from "react";
import { IProductCriteriaSliderConfiguration } from "../types/interfaces";
import Slider from "@mui/material/Slider";
import { ThemeProvider, createTheme } from "@mui/material";

interface Props {
    criteriaId: string;
    sliderConfiguration: IProductCriteriaSliderConfiguration;
    onChange: (criteriaId: string, minMax: [number, number]) => void;
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#98C44C"
        }
    }
});

/**
 * CriteriaSlider is a React component that enables users to adjust numerical values within a defined range via a slider interface.
 * It is optimized for flexible configuration, supporting both single and range selections, with optional fixed start or end points.
 */
function CriteriaSlider({
    criteriaId,
    sliderConfiguration,
    onChange
}: Readonly<Props>) {
    const getInitialValue = useCallback(() => {
        if (!sliderConfiguration.hasRange) {
            return sliderConfiguration.min;
        }
        if (sliderConfiguration.startFixed) {
            return sliderConfiguration.max;
        }
        if (sliderConfiguration.endFixed) {
            return sliderConfiguration.min;
        }
        return [sliderConfiguration.min, sliderConfiguration.max];
    }, [sliderConfiguration.min, sliderConfiguration.max]);

    const getTrack = useCallback(() => {
        if (!sliderConfiguration.hasRange) {
            return false;
        }
        if (sliderConfiguration.endFixed) {
            return "inverted";
        }
        return "normal";
    }, [sliderConfiguration.hasRange, sliderConfiguration.endFixed]);

    const value = useMemo(() => getInitialValue(), [getInitialValue]);
    const track = useMemo(() => getTrack(), [getTrack]);

    const marks = [
        {
            value: sliderConfiguration.rangeStart,
            label: valuetext(sliderConfiguration.rangeStart)
        },
        {
            value: sliderConfiguration.rangeEnd,
            label: valuetext(sliderConfiguration.rangeEnd)
        }
    ];

    function valuetext(value: number) {
        return `${value} ${sliderConfiguration.unit}`;
    }

    const sliderValueChange = (value: number | number[]) => {
        if (Array.isArray(value)) {
            onChange(criteriaId, [value[0], value[1]]);
        } else if (!sliderConfiguration.hasRange) {
            onChange(criteriaId, [value, value]);
        } else if (sliderConfiguration.endFixed) {
            onChange(criteriaId, [value, sliderConfiguration.max]);
        } else if (sliderConfiguration.startFixed) {
            onChange(criteriaId, [sliderConfiguration.min, value]);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Slider
                size="small"
                marks={marks}
                getAriaValueText={valuetext}
                track={track}
                defaultValue={value}
                onChange={(_, newValue) => {
                    sliderValueChange(newValue);
                }}
                step={sliderConfiguration.step}
                min={sliderConfiguration.rangeStart}
                max={sliderConfiguration.rangeEnd}
                valueLabelFormat={valuetext}
                valueLabelDisplay="auto"
                color="primary"
            />
        </ThemeProvider>
    );
}

export default CriteriaSlider;
