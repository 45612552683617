import React, { createContext, useContext, useState, ReactNode } from "react";

interface NavigationDrawerContextType {
    isNavigationDrawerOpen: boolean;
    toggleDrawer: (value: boolean) => void;
}

const NavigationDrawerContext = createContext<
    NavigationDrawerContextType | undefined
>(undefined);

interface NavigationDrawerProviderProps {
    children: ReactNode;
}

export const NavigationDrawerProvider: React.FC<
    NavigationDrawerProviderProps
> = ({ children }) => {
    const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false);

    const toggleDrawer = (value: boolean) => {
        setIsNavigationDrawerOpen(value);
    };

    return (
        <NavigationDrawerContext.Provider
            value={{ isNavigationDrawerOpen, toggleDrawer }}
        >
            {children}
        </NavigationDrawerContext.Provider>
    );
};

export const useNavigationDrawer = (): NavigationDrawerContextType => {
    const context = useContext(NavigationDrawerContext);
    if (context === undefined) {
        throw new Error(
            "useNavigation must be used within a NavigationProvider"
        );
    }
    return context;
};
