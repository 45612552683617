interface IZipCodeConfirmationMessageProps {
    zipCode: number;
    radius: number;
}

function ZipCodeConfirmationMessage({
    zipCode,
    radius
}: Readonly<IZipCodeConfirmationMessageProps>) {
    return (
        <div className="confirmation-message flex">
            <div className="icon-waiting hidden-mobile"></div>
            <div>
                <h2 className="mt-10 mb-10">Ihr Zielgebiet wird aktualisiert</h2>
                <p>
                    In Zukunft erhalten Sie ausschließlich Anfragen aus PLZ{" "}
                    {zipCode} (+ Umkreis {radius} km).
                </p>
            </div>
        </div>
    );
}

export default ZipCodeConfirmationMessage;
