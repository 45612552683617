/**
 * @enum InputType
 * Represents different input types for criteria.
 * @property {number} Options - Indicates that the criteria uses options as input.
 * @property {number} Slider - Indicates that the criteria uses a slider as input.
 */
export enum InputType {
    Options = 0,
    Slider = 1
}
