import React from "react";
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox
} from "@mui/material";
import { BookingAreaType } from "../types/enums";

interface Props {
    areaId: string;
    title: string;
    count: number;
    onCheckboxChanged: (checked: boolean, areaId: string) => void;
    checkboxDisabled: boolean;
    checkboxChecked: boolean;
    zipCodeDetails?: string;
}

/**
 * BookingSingleZipCodeGroup is a React component designed to manage and display an individual
 * zip code group within an accordion structure. This component allows users to toggle the visibility
 * of zip code details and to enable or disable selections based on certain criteria.
 *
 * Key Features:
 *  - Toggling of zip code details via an expandable accordion component.
 *  - Checkbox to enable or disable inclusion of the area in certain operations or selections.
 *  - Conditional rendering and interaction based on the area type, such as disabling for nationwide areas.
 */
function BookingSingleZipCodeGroup({
    areaId,
    title,
    count,
    onCheckboxChanged,
    zipCodeDetails,
    checkboxDisabled,
    checkboxChecked
}: Readonly<Props>) {
    function handleCheckboxChange(
        event: React.ChangeEvent<HTMLInputElement>
    ): void {
        event.stopPropagation();
        onCheckboxChanged(event.target.checked, areaId);
    }

    function isNationwide(): boolean {
        return areaId === BookingAreaType.Nationwide;
    }

    return (
        <Accordion
            elevation={0}
            disableGutters
            expanded={!isNationwide() ? undefined : false}
            onChange={!isNationwide() ? undefined : () => {}}
        >
            <AccordionSummary
                expandIcon={
                    !isNationwide() && <div className="expand-icon"></div>
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
            >
                <div
                    className={`accordion-heading ${
                        isNationwide() ? "pl-40" : ""
                    }`}
                >
                    <Typography
                        className={!checkboxChecked ? "text-disabled" : ""}
                    >
                        {title}
                    </Typography>
                    <Typography
                        className={
                            !checkboxChecked
                                ? "text-disabled hidden-mobile"
                                : "hidden-mobile"
                        }
                    >
                        {count}
                    </Typography>
                    <Checkbox
                        disabled={checkboxDisabled}
                        checked={checkboxChecked}
                        onChange={(event) => {
                            handleCheckboxChange(event);
                        }}
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    />
                </div>
            </AccordionSummary>
            <AccordionDetails className="zipcodes">
                <div>
                    {" "}
                    <b>Gruppe {title}</b>
                </div>
                <div> {zipCodeDetails}</div>
            </AccordionDetails>
        </Accordion>
    );
}

export default React.memo(BookingSingleZipCodeGroup);
