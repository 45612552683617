export enum BudgetUnit {
    Daily = 0,
    Weekly = 1,
    Monthly = 2
}

export enum LeadType {
    SingleLeads = 1, // The supplier only accepts exclusive leads, so no other supplier should receive a lead for the same product
    DualLeads = 2, // The supplier accepts at most 2er leads: so only one other supplier could receive a lead for the same product as well
    TrippleLeads = 3 // *Default*: The supplier accepts 3er leads: up to two other suppliers could receive a lead for the same product
}

export enum EffectiveLeadNettoPriceAdjustmentState {
        /// <summary>
        /// If supplier do not have any invoice to Inkasso then state will be normal
        /// </summary>
        Normal = 0,

        /// <summary>
        /// When the reminder level is Inkasso, and the booking's effective lead price decreases by 50%.
        /// </summary>
        DecreasedByInkasso = 1,
}

export enum BookingDetailsContainerTab {
    CRITERIA = "Vorqualifizierung",
    BudgetOptimization = "Budgetoptimierung",
    ZipCode = "Zielgebiet"
}

export enum BookingAreaType {
    Nationwide = "Nationwide"
}
