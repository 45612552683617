

///In the situation that we have a personal product we have different text - more details #7411

export function getModalTextsByProductType(isPersonalProduct: boolean) {

    let freeCapacityModalTitle = "Freie Kapazitäten melden";
    let freeCapacityModalDescription = "<strong>Gewinnen Sie einen neuen Klienten</strong> und geben Sie Rückmeldung zu Ihren freien Kapazitäten:";
    let freeCapacityModalCheckBox1Text = "Neue Klienten gewinnen";
    let freeCapacityModalButtonText = "Jetzt freie Kapazitäten melden";

    let noCapacityModalTitle = "Keine freien Kapazitäten melden";
    let noCapacityModalLeftDescription = "Teilen Sie dem Klienten mit, wenn Sie <strong>keine freien Kapazitäten</strong> haben. Jede Rückmeldung hilft dem Klienten bei der Anbieter-Suche.";
    let noCapacityModalRightDescription = "Mit einer positiven Rückmeldung gewinnen Sie schnell und unkompliziert einen <strong>neuen Klienten.</strong>";
    let noCapacityModalRightCheckbox1 = "Neue Klienten gewinnen";
    let noCapacityModalButtonText = "Keine freien Kapazitäten melden";

    let approvedStateTopMessage = "Sie haben Ihre freien Kapazitäten erfolgreich gemeldet.";
    let approvedStateGreenBoxList1 = "Die Kontaktdaten des Klienten sind nun für Sie <strong>freigeschaltet</strong>";
    let approvedStateGreenBoxList2 = "<strong>Nehmen Sie Kontakt zu dem Klienten</strong> auf und stellen Sie sich als Anbieter vor.";
    let approvedStateGreenBoxTip = "Warten Sie nicht mit der Kontaktaufnahme. In der Zwischenzeit könnte der Klient sich für einen anderen Anbieter entscheiden.";
    let approvedStateContactDetailsTableHeader = "Kontaktdaten des Klienten";
    let productCriteriaDetailsTableHeader = "Produktkriterien";

    let closedStateTopMessage = "Der Klient hat die Anfrage geschlossen.";
    let closedStateRedBox = "Der Klient hat bereits einen Anbieter gefunden. Es ist keine Kontaktaufnahme Ihrerseits notwendig.";
    let closedStateContactDetailsTableHeader = "Die wichtigsten Daten auf einen Blick";

    let closedStateAfterReportedCapacityTopMessage = "Der Klient hat die Anfrage geschlossen.";
    let closedStateAfterReportedTitle1 = "Sie hatten noch keinen Kontakt zu dem Klienten?";
    let closedStateAfterReportedCapacityDescription1 = "Es ist keine Kontaktaufnahme Ihrerseits notwendig. In der Zwischenzeit hat der Klient sich umentschieden und die Anfrage geschlossen.";
    let closedStateAfterReportedTitle2 = "Sie sind der Anbieter, für den sich der Klient entschieden hat?";
    let closedStateAfterReportedCapacityDescription2 = "Herzlichen Glückwunsch! Wir freuen uns, wenn Sie durch unseren Service einen neuen Klienten gewinnen konnten.";


    let openCapacityStateHasCapacityButtonText = "Ja, wir haben freie Kapazitäten »";
    let openCapacityStateNoCapacityButtonText = "Nein, wir haben keine Kapazitäten »";
    let openCapacityStateTopDescription = "<strong>Gewinnen Sie neue Klienten</strong> und melden Sie Ihre Kapazitäten. Dieser Service ist für Sie <strong>100 % kostenlos.</strong>"
    let openCapacityStateProductionCriteriaDescription = "<strong>Ihnen werden nicht alle Informationen angezeigt?</strong> Sobald Sie freie Kapazitaten melden, erhalten Sie Zugriff auf die Informationen.";

    if (isPersonalProduct) {
        freeCapacityModalTitle = "Freie Stellen melden";
        freeCapacityModalDescription = "<strong>Gewinnen Sie neues Personal</strong> und geben Sie Rückmeldung zu Ihren freien Stellen:";
        freeCapacityModalCheckBox1Text = "Neues Personal gewinnen";
        freeCapacityModalButtonText = "Jetzt freie Stellen melden";

        noCapacityModalTitle = "Keine freien Stellen melden";
        noCapacityModalLeftDescription = "Teilen Sie dem Bewerber mit, wenn Sie <strong>keine freien Stellen</strong> haben. Jede Rückmeldung hilft dem Bewerber bei der Job-Suche. ";
        noCapacityModalRightDescription = " Mit einer positiven Rückmeldung gewinnen Sie schnell und unkompliziert <strong>neues Personal.</strong>";
        noCapacityModalRightCheckbox1 = " Neues Personal gewinnen";
        noCapacityModalButtonText = "Keine freien Stellen melden";

        approvedStateTopMessage = "Sie haben Ihre freien Stellen erfolgreich gemeldet.";
        approvedStateGreenBoxList1 = "Die Kontaktdaten des Bewerbers sind nun für Sie <strong>freigeschaltet.</strong>";
        approvedStateGreenBoxList2 = "<strong>Nehmen Sie Kontakt zu dem Bewerber</strong> auf und stellen Sie sich als Arbeitgeber vor.";
        approvedStateGreenBoxTip = "Warten Sie nicht mit der Kontaktaufnahme. In der Zwischenzeit könnte der Bewerber sich für einen anderen Arbeitgeber entscheiden";
        approvedStateContactDetailsTableHeader = "Kontaktdaten des Bewerbers";
        productCriteriaDetailsTableHeader = "Details Bewerbung";

        closedStateTopMessage = "Der Bewerber hat die Anfrage geschlossen.";
        closedStateRedBox = "Der Bewerber hat bereits einen Arbeitgeber gefunden. Es ist keine Kontaktaufnahme Ihrerseits notwendig.";
        closedStateContactDetailsTableHeader = "Kontaktdaten des Bewerbers";


        openCapacityStateHasCapacityButtonText = "Ja, wir haben freie Stellen »";
        openCapacityStateNoCapacityButtonText = "Nein, wir haben keine freien Stellen »";
        openCapacityStateTopDescription = "<strong>Gewinnen Sie neues Personal</strong> und melden Sie Ihre freien Stellen. Dieser Service ist für Sie <strong>100 % kostenlos.</strong>";
        openCapacityStateProductionCriteriaDescription = "<strong>Ihnen werden nicht alle Informationen angezeigt?</strong> Sobald Sie freie Stellen melden, erhalten Sie Zugriff auf die Informationen."
        
        closedStateAfterReportedCapacityTopMessage = "Der Bewerber hat die Anfrage geschlossen.";
        closedStateAfterReportedTitle1 = "Sie hatten noch keinen Kontakt zu dem Bewerber?";
        closedStateAfterReportedCapacityDescription1 = "Es ist keine Kontaktaufnahme Ihrerseits notwendig. In der Zwischenzeit hat der Bewerber sich umentschieden und die Anfrage geschlossen.";
        closedStateAfterReportedTitle2 = "Sie sind der Arbeitgeber, für den sich der Bewerber entschieden hat?";
        closedStateAfterReportedCapacityDescription2 = "Herzlichen Glückwunsch! Wir freuen uns, wenn Sie durch unseren Service neues Personal gewinnen konnten.";
    }

    return {
        freeCapacityModalTitle,
        freeCapacityModalDescription,
        freeCapacityModalCheckBox1Text,
        freeCapacityModalButtonText,
        noCapacityModalTitle,
        noCapacityModalLeftDescription,
        noCapacityModalRightDescription,
        noCapacityModalRightCheckbox1,
        noCapacityModalButtonText,
        approvedStateTopMessage,
        approvedStateGreenBoxList1,
        approvedStateGreenBoxList2,
        approvedStateGreenBoxTip,
        approvedStateContactDetailsTableHeader,
        approvedStateProductCriteriaDetailsTableHeader: productCriteriaDetailsTableHeader,
        closedStateTopMessage,
        closedStateRedBox,
        closedStateContactDetailsTableHeader,
        closedStateAfterReportedCapacityTopMessage,
        closedStateAfterReportedTitle1,
        closedStateAfterReportedCapacityDescription1,
        closedStateAfterReportedTitle2,
        closedStateAfterReportedCapacityDescription2,
        openCapacityStateHasCapacityButtonText,
        openCapacityStateNoCapacityButtonText,
        openCapacityStateTopDescription,
        openCapacityStateProductionCriteriaDescription
    };
}