import "../../assets/styles/components/MobileNavigationDrawer.scss";

interface Props {
    children?: React.ReactNode;
    className?: string;
    hidden?: boolean;
}

/**
 * MobileNavigationDrawer is a React component that serves as a container for a mobile-friendly
 * navigation drawer. This component provides a flexible structure for encapsulating navigational
 * elements or any other content intended to be displayed in a sliding drawer format on mobile devices.
 * The drawer's visibility can be dynamically controlled via props.
 *
 */
function MobileNavigationDrawer({
    children,
    className,
    hidden
}: Readonly<Props>) {
    return (
        <div hidden={hidden} className={`navigation-drawer ${className}`}>
            {children}
        </div>
    );
}

export default MobileNavigationDrawer;
