import "../../../assets/styles/waitingListLead/WaitingListLeadState.scss";
import { getModalTextsByProductType } from "../utils";



interface Props {
    isPersonalProduct: boolean;
}


/**
 *
 * WaitingListLeadStateClosed is a React component that displays a notification to inform
 * suppliers that a specific waiting list lead has been closed by the client.
 */

function WaitingListLeadStateAcquiredWithClosedDate({isPersonalProduct}:Readonly<Props>){

    const { 
        closedStateAfterReportedCapacityTopMessage,
        closedStateAfterReportedTitle1,
        closedStateAfterReportedCapacityDescription1,
        closedStateAfterReportedTitle2,
        closedStateAfterReportedCapacityDescription2
    } = getModalTextsByProductType(isPersonalProduct); 

    return (
        <div className="waiting-list-lead-closed-container">
            <div className="flex-nowrap align-center mb-20">
                <div className="icon icon-close-pink"></div>
                <h3 className="text-light-orange">
                    {closedStateAfterReportedCapacityTopMessage}
                </h3>
            </div>
            <div className="waiting-list-lead-status-box close-box mb-20 ">
                <div>
                    <h3>{closedStateAfterReportedTitle1}</h3>
                    {closedStateAfterReportedCapacityDescription1}
                </div>
                <div>
                    <h3>
                        {closedStateAfterReportedTitle2}
                    </h3>
                    {closedStateAfterReportedCapacityDescription2}
                </div>
            </div>
        </div>
    );
}

export default WaitingListLeadStateAcquiredWithClosedDate;
