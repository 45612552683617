import { Link, useParams } from "react-router-dom";
import "../../../assets/styles/waitingListBookingChangeRequest/ChangeRequest.scss";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { useEffect, useState } from "react";
import {
    convertWaitingListLeadReceiptToFormData,
    convertWaitingListLeadReceiptToRequest
} from "../utils/leadReceiptsContactInformationOverview/mapper";
import LeadReceiptsContactInformationForm, {
    ILeadReceiptsContactInformationFormData
} from "./LeadReceiptsContactInformationForm";
import LeadReceiptConfirmationMessage from "./LeadReceiptConfirmationMessage";
import LeadReceiptConfirmationModal from "./LeadReceiptConfirmationModal";
import {
    useGetWaitingListLeadReceiptsInfo,
    usePostSendWaitingListBookingLeadReceipt
} from "../services/waitingListBookingChangeRequestService";
import { ILeadReceipt } from "../services/dtos";
import { toast } from "react-toastify";

function LeadReceiptsContactInformationOverview() {
    const { supplierId } = useParams() as { supplierId: string };
    const { secureId } = useParams() as { secureId: string };
    const [selectedContactId, setSelectedContactId] = useState("");
    const [sentContactIds, setSentContactIds] = useState<string[]>([]);
    const [confirmationModel, setConfirmationModel] = useState<boolean>(false);
    const errorMessage =
        "Kontaktdaten wurden aktualisiert. Bitte probieren Sie es erneut.";

    const { mutate: sendLeadReceipt } =
        usePostSendWaitingListBookingLeadReceipt(supplierId, {
            onSuccess: () => {
                submitOnSuccess(selectedContactId);
            },
            onError: () => {
                toast.error(errorMessage);
            }
        });

    const { data: leadReceiptsInfo, isSuccess } =
        useGetWaitingListLeadReceiptsInfo(supplierId);

    useEffect(() => {
        if (
            isSuccess &&
            leadReceiptsInfo?.waitingListBookingLeadReceipts.length > 0
        ) {
            const id = leadReceiptsInfo.waitingListBookingLeadReceipts[0].id;
            setSelectedContactId(id);
        }
    }, [isSuccess, leadReceiptsInfo]);

    function updateContactInfoOnSubmit(
        formData: ILeadReceiptsContactInformationFormData
    ) {
        const selectedLeadReceiptExistingContactInfo =
            leadReceiptsInfo?.waitingListBookingLeadReceipts.find(
                (x) => x.id === selectedContactId
            ) as ILeadReceipt;
        const data = convertWaitingListLeadReceiptToRequest(
            selectedLeadReceiptExistingContactInfo,
            formData
        );
        sendLeadReceipt(data);
    }

    const handleContactChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        setSelectedContactId(selectedValue);
    };

    const submitOnSuccess = (contactId: string) => {
        setSentContactIds((prevIds) => [...prevIds, contactId]);
        setConfirmationModel(true);
    };

    const isContactAlreadySent = (contactId: string): boolean => {
        return sentContactIds.includes(contactId);
    };

    function hasMultipleContactPersons(): ILeadReceipt[] | false {
        // Check conditions similar to a 'type guard' but for our specific logic
        if (
            isSuccess &&
            leadReceiptsInfo &&
            leadReceiptsInfo.waitingListBookingLeadReceipts.length > 1
        ) {
            // Condition meets our criteria to treat it as a 'successful' type
            return leadReceiptsInfo.waitingListBookingLeadReceipts;
        } else {
            // Does not meet our criteria, return a 'false' type
            return false;
        }
    }

    return (
        <div className="waiting-list-booking-change-request-container">
            <h1 className="text-blue mtb-25">Kontaktdaten</h1>
            <div className="flex-wrap align-center">
                <h3 className="highlight-green mtb-0">
                    Sind Ihre Kontaktdaten korrekt hinterlegt?
                </h3>
            </div>
            <p className="text-black">
                Halten Sie Ihre Kontaktdaten auf dem aktuellen Stand, um{" "}
                <strong>keine Anfragen zu verpassen.</strong>
            </p>
            <div className="change-request-form-container">
                <p className="change-request-form-heading">
                    Kontaktinformationen
                </p>
                {hasMultipleContactPersons() && (
                    <div className="mb-20">
                        <div className="col-7 label-in-row-desktop contact-person-dropdown-form">
                            <label className="bolder">Ansprechpartner:</label>
                            <FormControl fullWidth margin="normal" className="mtb-15">
                                <InputLabel id="contact-dropdown-label">
                                    Ansprechpartner
                                </InputLabel>
                                <Select
                                    labelId="contact-dropdown-label"
                                    className="contact-person-dropdown"
                                    value={selectedContactId}
                                    onChange={handleContactChange}
                                    label="Ansprechpartner"
                                >
                                    {leadReceiptsInfo!.waitingListBookingLeadReceipts.map(
                                        (receipt) => (
                                            <MenuItem
                                                key={receipt.id}
                                                value={receipt.id}
                                            >{`${receipt.email}`}</MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <hr/>
                    </div>
                )}
                {isContactAlreadySent(selectedContactId) ? (
                    <LeadReceiptConfirmationMessage />
                ) : (
                    <LeadReceiptsContactInformationForm
                        key={selectedContactId}
                        leadReceiptsContactInformationFormData={convertWaitingListLeadReceiptToFormData(
                            leadReceiptsInfo?.waitingListBookingLeadReceipts.find(
                                (x) => x.id === selectedContactId
                            ) as ILeadReceipt
                        )}
                        contactId={selectedContactId}
                        onSubmit={updateContactInfoOnSubmit}
                    />
                )}
            </div>

            <div className="mb-10 user-tip-class show-small-screen">
                    <h4 className="highlight-blue">Unser Tipp</h4>
                    Hinterlegen Sie jetzt auch Ihr{" "}
                    <strong>individuell festgelegtes Zielgebiet</strong> und
                    erhalten Sie nur Anfragen aus Ihrem Wunsch-Umkreis.{" "}
                    <Link className="text-blue"
                        to={`/${supplierId}/waitinglistrequests/${secureId}/zipcodeform`}
                    >
                        Zielgebiet hinterlegen »
                    </Link>
            </div>

            <LeadReceiptConfirmationModal
                popUpOpen={confirmationModel}
                setPopUpOpen={setConfirmationModel}
            />
        </div>
    );
}

export default LeadReceiptsContactInformationOverview;
