import React from "react";
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material";

interface Props {
    count: number;
    isNationWide: boolean;
    children?: React.ReactNode;
}

/**
 * BookingZipCodeActiveDetails is a React component that displays details about active postal codes
 * related to a booking. This component utilizes an accordion to selectively
 * display the content, which is particularly useful for managing visual clutter in interfaces that
 * require detailed geographical data.
 *
 * The accordion can be expanded or collapsed based on whether the area is nationwide or more localized.
 *
 */
function BookingZipCodeActiveDetails({
    count,
    isNationWide,
    children
}: Readonly<Props>) {
    return (
        <Box mb={3}>
            <div className="accordion-heading zipcode-subheading pr-10">
                <Typography>Aktive Postleitzahlen insgesamt</Typography>
            </div>
            <Accordion
                key={isNationWide.toString()}
                elevation={0}
                disableGutters
                expanded={isNationWide ? false : undefined}
                className={isNationWide ? "cursor-default" : ""}
            >
                <AccordionSummary
                    expandIcon={
                        isNationWide ? (
                            <div className="mr-15"></div>
                        ) : (
                            <div className="expand-icon"></div>
                        )
                    }
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <div className="accordion-heading">
                        <Typography>{count}</Typography>
                    </div>
                </AccordionSummary>
                <AccordionDetails className="zipcodes">
                    {children}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

export default BookingZipCodeActiveDetails;
