/**
 * BookingChangesHint is a React component that displays a notification hint
 * within the user interface. It specifically informs users that changes to their
 * booking are not automatically saved
 *
 */
function BookingChangesHint() {
    return (
        <div className="text-blue mb-20">
            <i>
                <u>Hinweis</u>: Änderungen an Ihrer Buchung werden nicht
                gespeichert. <b>Kontaktieren Sie Ihre/n Ansprechpartner/in</b>,
                um Änderungen zu speichern.
            </i>
        </div>
    );
}

export default BookingChangesHint;
