import { IListOfBooking } from "../../services/dtos";

export function sortListByProductNameAndBookingNumber(
    list: readonly IListOfBooking[]
): IListOfBooking[] {
    const listCopy = [...list];
    return listCopy.sort((a, b) => {
        const productNameComparison = a.productName.localeCompare(
            b.productName
        );

        if (productNameComparison === 0) {
            // Sort by bookingNumber in descending order (highest first)
            return b.bookingNumber - a.bookingNumber;
        }

        return productNameComparison;
    });
}
