import cloneDeep from "lodash/cloneDeep";
import { Criteria, ICriteria } from "../model/criteria";
import { PrecalculatedCriteriaMatching } from "../model/precalculatedCriteriaMatching";
import { OptionResults } from "../model/types/optionResults";
import { SliderResults } from "../model/types/sliderResults";
import { deepFreeze } from "../utils/deepFreeze";
import { ICriteriaGroupList } from "../model/criteriaGroupList";

type ProductCriteriaDataStore = Pick<
    PrecalculatedCriteriaMatching,
    "criteriaFulFilled"
>;

/**
/**
 * @interface IProductCriteriaService
 * Represents a service that interacts with product criteria data.
 * @property {number} productCriteriaPotential - The potential matches based on current criteria values for all criteria.
 * @property {boolean[]} matchingArray - An array indicating if each request (for booking side) or booking (for request side) matches the criteria.
 * @method getCriteriaById - Retrieves the criteria object associated with a specific criteria ID.
 * @method getCriteriaGroupList - Retrieves a list of criteria groups with associated criteria.
 */
export interface IProductCriteriaService {
    productCriteriaPotential: number;
    matchingArray: boolean[];
    getCriteriaById(criteriaId: string): ICriteria;
    getCriteriaGroupList(): ICriteriaGroupList[];
}

/**
 * @interface IProductCriteriaDataManagementService
 * This interface is representing a service responsible for managing product criteria data.
 * @method updateProductCriteriaDataStore - Update the criteria fullfilled object
 * @method getOptionScenarioByCriteriaId - Retrieves readonly option scenario object associated with a specific criteria ID.
 * @method getCountingsByCriteriaId - Retrieves readonly counting object associated with a specific criteria ID.
 * @method getSliderScenarioByCriteriaId - Retrieves readonly slider scenario object associated with a specific criteria ID.
 */
export interface IProductCriteriaDataManagementService {
    updateProductCriteriaDataStore(): void;
    getOptionScenarioByCriteriaId(criteriaId: string): Readonly<OptionResults>;
    getCountingsByCriteriaId(criteriaId: string): readonly number[];
    getSliderScenarioByCriteriaId(criteriaId: string): Readonly<SliderResults>;
}

/**
 * Creates a new instance of ProductCriteriaService with a deep cloned
 * copy of precalculatedCriteriaMatching. This ensures that any modifications
 * made to the cloned object do not affect the original object outside this function.
 *
 * @param criteriaList - List of criteria.
 * @param precalculatedCriteriaMatching - Precalculated criteria matching data.
 * @returns An instance of IProductCriteriaService.
 */
export function createProductCriteriaService(
    criteriaList: ICriteria[],
    precalculatedCriteriaMatching: PrecalculatedCriteriaMatching
): IProductCriteriaService {
    return new ProductCriteriaService(
        criteriaList,
        cloneDeep(precalculatedCriteriaMatching)
    );
}

/**
 * Creates a new instance of ProductCriteriaService with a deep cloned
 * copy of precalculatedCriteriaMatching. This ensures that any modifications
 * made to the cloned object do not affect the original object outside this function.
 *
 * @param criteriaGroupList - List of criteria groups, each containing a group name, order index, and associated criteria list.
 * @param precalculatedCriteriaMatching - Precalculated criteria matching data.
 * @returns An instance of IProductCriteriaService.
 */
export function createProductCriteriaServiceByGroup(
    criteriaGroupList: ICriteriaGroupList[],
    precalculatedCriteriaMatching: PrecalculatedCriteriaMatching
): IProductCriteriaService {
    const criteriaList: ICriteria[] = criteriaGroupList.flatMap((group) =>
        group.criteriaList.map((criteria) => {
            criteria.groupName = group.groupName;
            criteria.groupOrderIndex = group.groupOrderIndex;
            return criteria;
        })
    );
    return new ProductCriteriaService(
        criteriaList,
        cloneDeep(precalculatedCriteriaMatching)
    );
}

export class ProductCriteriaService
    implements IProductCriteriaDataManagementService, IProductCriteriaService
{
    private CriteriaList: ICriteria[] = [];
    private productCriteriaDataStore: ProductCriteriaDataStore;

    constructor(
        criteriaList: ICriteria[],
        private precalculatedCriteriaMatching: Readonly<PrecalculatedCriteriaMatching>
    ) {
        /**The data store contains the information, we want to change after the value of criteria changes. */
        this.productCriteriaDataStore = {
            criteriaFulFilled: [
                ...precalculatedCriteriaMatching.criteriaFulFilled
            ]
        };
        // Initial object can not be modified
        deepFreeze(precalculatedCriteriaMatching);
        // Initialize criteria list, this is needed so we can use the classes functions.
        this.CriteriaList = criteriaList.map((x) => new Criteria(x, this));
    }

    getCriteriaGroupList(): ICriteriaGroupList[] {
        const groupedCriteria: Record<string, ICriteria[]> = {};

        this.CriteriaList.forEach((criteria) => {
            if (!groupedCriteria[criteria.groupName]) {
                groupedCriteria[criteria.groupName] = [];
            }
            groupedCriteria[criteria.groupName].push(criteria);
        });

        return Object.keys(groupedCriteria)
            .map((groupName): ICriteriaGroupList => {
                return {
                    groupName,
                    groupOrderIndex:
                        groupedCriteria[groupName][0].groupOrderIndex,
                    criteriaList: groupedCriteria[groupName]
                };
            })
            .sort((a, b) => a.groupOrderIndex - b.groupOrderIndex);
    }

    getCriteriaById(criteriaId: string): ICriteria {
        const criteria = this.CriteriaList.find(
            (x) => x.criteriaId === criteriaId
        );
        if (!criteria) {
            throw new Error(
                `CriteriaId "${criteriaId}" not found in CriteriaList`
            );
        }
        return criteria;
    }

    get productCriteriaPotential() {
        return this.productCriteriaDataStore.criteriaFulFilled.filter(
            (x) => x >= this.CriteriaList.length
        ).length;
    }

    get matchingArray() {
        return this.productCriteriaDataStore.criteriaFulFilled.map(
            (x) => x === this.CriteriaList.length
        );
    }

    updateProductCriteriaDataStore(): void {
        const criteriaFulFilled =
            this.productCriteriaDataStore.criteriaFulFilled;
        criteriaFulFilled.forEach((_, index) => {
            let numberFullFilled = 0;
            this.CriteriaList.forEach((criteria) => {
                numberFullFilled += criteria.isCriteriaMatchedByIndex(index)
                    ? 1
                    : 0;
            });
            criteriaFulFilled[index] = numberFullFilled;
        });
    }

    getOptionScenarioByCriteriaId(criteriaId: string): Readonly<OptionResults> {
        // Check if the criteriaId exists in the precalculated results.
        const optionScenarioResults =
            this.precalculatedCriteriaMatching.optionScenarioResults;
        if (!(criteriaId in optionScenarioResults)) {
            throw new Error(
                `CriteriaId "${criteriaId}" not found in OptionScenarioResults.`
            );
        }
        return optionScenarioResults[criteriaId];
    }

    getSliderScenarioByCriteriaId(criteriaId: string): Readonly<SliderResults> {
        // Check if the criteriaId exists in the precalculated results.
        const sliderScenarioResults =
            this.precalculatedCriteriaMatching.sliderScenarioResults;
        if (!(criteriaId in sliderScenarioResults)) {
            throw new Error(
                `CriteriaId "${criteriaId}" not found in SliderScenarioResults.`
            );
        }
        return sliderScenarioResults[criteriaId];
    }

    getCountingsByCriteriaId(criteriaId: string): readonly number[] {
        // Check if the criteriaId exists in the precalculated results.
        const countings = this.precalculatedCriteriaMatching.countings;
        if (!(criteriaId in countings)) {
            throw new Error(
                `CriteriaId "${criteriaId}" not found in Countings.`
            );
        }
        return countings[criteriaId];
    }
}
