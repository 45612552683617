export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export enum BookingPotentialCategory {
    LeadPrice1 = 0,
    LeadPrice2 = 1,
    LeadPrice3 = 2,
    PostalCode = 3,
    EmailVerified = 4,
    PostalAddressRequired = 5,
    NoCrossSelling = 6,
    WeekDay = 11
}

export enum PhoneSort {
    Telephone = 0,
    Mobile = 1,
    Fax = 2
}
