import "../../assets/styles/components/NotFoundPage.scss";

interface Props {
    statusCode: number;
}

/**
 *
 * ErrorPage is a React component that provides a user-friendly error message interface in our application. It
 * displays different messages based on the HTTP status code provided as a prop. This component helps in delivering a
 * better user experience by informing users about the nature of the error and guiding them appropriately.
 *
 */
function ErrorPage({ statusCode }: Readonly<Props>) {
    return (
        <div className="not-found-container">
            <div className="not-found-body">
                <div className="logo"></div>
                {statusCode === 404 && (
                    <>
                        <h1>404. Es liegt ein fehler vor.</h1>
                        <h2>Diese Seite konnte nicht gefunden werden.</h2>
                    </>
                )}
                {(statusCode === 500 || statusCode === 503) && (
                    <h1>Es liegt ein Fehler vor.</h1>
                )}
            </div>
        </div>
    );
}

export default ErrorPage;
