interface IOptionPresentation {
    orderIndex: number;
}
/**
 * @interface IOption
 * Represents an option within a product criteria.
 * @property {string} optionsName - The name or label of the option.
 * @property {string} optionId - The unique identifier of the option.
 * @property {string} implies - The identifier of the option that this option implies.
 * @property {boolean} isHidden - Indicates whether the option is hidden from user view.
 * @property {boolean} isSelected - Indicates whether the option is currently selected.
 * @property {number} localPotential - The potential change in local matches if the value of this option changes.
 */
export interface IOption extends IOptionPresentation {
    optionsName: string;
    optionId: string;
    implies: string;
    isHidden: boolean;
    isSelected: boolean;

    /** How would the local potential change if the value of this option would change? */
    localPotential: number;
}

export class Option implements IOption {
    public orderIndex: number;
    public optionsName: string;
    public optionId: string;
    public implies: string;
    public isHidden: boolean;
    public isSelected: boolean;
    public localPotential: number = -1;

    constructor(objFromJson: IOption) {
        this.orderIndex = objFromJson.orderIndex;
        this.optionsName = objFromJson.optionsName;
        this.optionId = objFromJson.optionId;
        this.implies = objFromJson.implies;
        this.isHidden = objFromJson.isHidden;
        this.isSelected = objFromJson.isSelected;
    }
}
