import { Box, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useFormValidationRules } from "../hooks/leadReceiptsContactInformationForm/useFormValidationRules";

export interface IZipCodeInformationFormData {
    zipCode: string;
    radius: string;
}

interface IZipCodeInformationFormProps {
    onSubmit: (waitingListZipCodeFormData: IZipCodeInformationFormData) => void;
}

function ZipCodeInformationForm({
    onSubmit
}: Readonly<IZipCodeInformationFormProps>) {
    const {
        handleSubmit,
        formState: { isDirty },
        control,
        reset
    } = useForm<IZipCodeInformationFormData>({
        mode: "onBlur"
    });

    useEffect(() => {
        reset();
    }, [reset]);

    const { zipCodeRules, radiusRules } = useFormValidationRules({});

    return (
        //using controller to control the form fields for managing controlled components like checkbox
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="zipcode-tip-class mb-20 mt-20">
                Zurzeit ist es uns leider nicht möglich Ihr Zielgebiet hier
                anzuzeigen. Wir arbeiten bereits an einer Lösung.{" "}
                <strong>Änderungen</strong> an Ihrem Zielgebiet{" "}
                <strong>werden dennoch gespeichert,</strong> so dass Sie nur
                Anfragen aus Ihrer Wunsch-Region erhalten.
            </div>
            <div className="info-section">
                <div className="flex">
                    <div className="col-6 mr-15-mobile">
                        <label className="bolder">Postleitzahl*:</label>
                        <Box className="input">
                            <Controller
                                name="zipCode"
                                rules={zipCodeRules}
                                defaultValue=""
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        className="empty-bg-change"
                                        placeholder="PLZ"
                                        variant="outlined"
                                        fullWidth
                                        error={!!error}
                                        helperText={
                                            error ? error.message : null
                                        }
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                    </div>
                    <div className="col-6">
                        <label className="bolder">Umkreis*:</label>
                        <div className="input-group flex">
                            <div className="col-8 zip-code-radius-input">
                                <Box className="input">
                                    <Controller
                                        name="radius"
                                        rules={radiusRules}
                                        defaultValue=""
                                        control={control}
                                        render={({
                                            field,
                                            fieldState: { error }
                                        }) => (
                                            <TextField
                                                {...field}
                                                placeholder="Umkreis"
                                                variant="outlined"
                                                className="empty-bg-change"
                                                fullWidth
                                                error={!!error}
                                                helperText={
                                                    error ? error.message : null
                                                }
                                                margin="normal"
                                            />
                                        )}
                                    />
                                </Box>
                            </div>
                            <div className="">
                                <div className="radius-unit">km</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-form-button-container">
                    <button
                        type="submit"
                        className="btn contact-form-button"
                        disabled={!isDirty}
                    >
                        Speichern
                    </button>
                </div>
            </div>
            <div>
                <i>
                    <u>Hinweis</u>: Bitte beachten Sie, dass die endgültige
                    Speicherung Ihrer Anpassungen mehrere Tage in Anspruch
                    nehmen kann.
                </i>
            </div>
        </form>
    );
}

export default ZipCodeInformationForm;
