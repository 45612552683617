//layout
const supplierFeature = "supplier";
export const getGetSupplierInfo = `${supplierFeature}-get-getSupplierInfo`;
//booking
const bookingFeature = "booking";
export const getGetBookingPotentialsInfo = `${bookingFeature}-get-getBookingPotentialsInfo`;
//zipcode
const zipCodeFeature = "zipCode";
export const getGetPostCodesByGeoItemId = `${zipCodeFeature}-get-GetPosCodesByGeoItemId`;
export const getGetAreasBySearch = `${zipCodeFeature}-get-GetAreasBySearch`;
export const getGetPostCodesByStartsWith = `${zipCodeFeature}-get-GetPostCodesByStartsWith`;
export const getGetPostCodesByStartsWithAndEndWith = `${zipCodeFeature}-get-GetPostCodesByStartsWithAndEndWith`;
export const getGetPostCodeValid = `${zipCodeFeature}-get-GetPostCodeValid`;
export const getGetPostCodesByRadius = `${zipCodeFeature}-get-GetPostCodesByRadius`;
//waitingListLead
const waitingListLeadFeature = "waitingListLead";
export const getGetWaitingListLeadBasicInfo = `${waitingListLeadFeature}-get-GetWaitingListLeadBasicInfo`;
export const getGetWaitingListLeadCriteriaInfo = `${waitingListLeadFeature}-get-GetWaitingListLeadCriteriaInfo`;
export const patchAcquireWaitingListLead = `${waitingListLeadFeature}-patch-AcquireWaitingListLead`;
export const patchRejectWaitingListLead = `${waitingListLeadFeature}-patch-RejectWaitingListLead`;
export const getGetWaitingListLeadReceiptsInfo = `${waitingListLeadFeature}-get-GetWaitingListLeadReceiptsInfo`;
export const postSendWaitingListBookingLeadReceiptInfo = `${waitingListLeadFeature}-post-SendWaitingBookingListLeadReceiptInfo`;
export const postSendWaitingListBookingZipCodeInfo = `${waitingListLeadFeature}-post-SendWaitingBookingListZipCodeInfo`;
