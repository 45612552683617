import { IProductCriteriaDataManagementService } from "../service/productCriteriaService";
import { convertBinaryStringToArray } from "../utils/convertBinaryStringToArray";
import {
    generateMappingOfAllOptions,
    findAllActualSelectedOption
} from "../utils/optionSelectionUtils";
import { subtractArrays } from "../utils/subtractArrays";
import { sumUpArrays } from "../utils/sumUpArrays";
import { OperatorRule } from "./enum/operatorRule";
import { IOption, Option } from "./option";
import { OptionResults, convertToIsCheckedType } from "./types/optionResults";

/**
 * @interface IOptionConfiguration
 * Represents the configuration of options within a product criteria.
 * @property {boolean} representAsList - Indicates whether options should be represented as a list.
 * @property {boolean} multipleValuesAllowed - Specifies if multiple values can be selected for the criteria.
 * @property {boolean} reverseExcludeOrder - Specifies whether to reverse the exclusion order of options.
 * @property {number} operatorRule - The rule to apply when multiple operators are involved.
 * @property {IOption[]} options - An array of options available for the criteria.
 * @method getOptionById - Retrieves an option by its unique ID.
 * @method handleChange - Triggers an update of potential values when option selections change.
 * @method isCriteriaMatchedByIndex - Check particular request/booking matched criteria
 */
export interface IOptionConfiguration {
    representAsList: boolean;
    multipleValuesAllowed: boolean;
    reverseExcludeOrder: boolean;
    operatorRule: number;
    options: IOption[];

    getOptionById(optionId: string): IOption;
    handleChange(): void;
    isCriteriaMatchedByIndex(index: number): boolean;
}

export class OptionConfiguration implements IOptionConfiguration {
    public representAsList: boolean;
    public multipleValuesAllowed: boolean;
    public reverseExcludeOrder: boolean;
    public operatorRule: OperatorRule;
    public options: IOption[] = [];

    private previousOptionsState: Map<string, boolean> = new Map<
        string,
        boolean
    >();
    /** Store the information of all options for any particular option for implicit logic */
    private mappingOfOptionIdsBySingleOptionId: Map<string, readonly string[]>;
    /** Store the information of criteria count */
    private criteriaCounting: number[] = [];
    /** Store the information of criteria fulfilled */
    private criteriaFulFilled: boolean[] = [];
    private optionScenarioForCriteria: Readonly<OptionResults>;

    constructor(
        objFromJson: IOptionConfiguration,
        private readonly criteriaId: string,
        /** Reference to the product criteria service to update potential values after changes. */
        private productCriteriaDataManagementService: IProductCriteriaDataManagementService
    ) {
        this.representAsList = objFromJson.representAsList;
        this.multipleValuesAllowed = objFromJson.multipleValuesAllowed;
        this.reverseExcludeOrder = objFromJson.reverseExcludeOrder;
        this.operatorRule = objFromJson.operatorRule;

        this.mappingOfOptionIdsBySingleOptionId = generateMappingOfAllOptions([
            ...objFromJson.options
        ]);
        const selectedOptions = findAllActualSelectedOption(
            objFromJson.options,
            this.mappingOfOptionIdsBySingleOptionId
        );
        objFromJson.options.forEach((option: IOption) => {
            this.options.push(new Option(option));
            this.previousOptionsState.set(
                option.optionId,
                selectedOptions.includes(option.optionId)
            );
        });
        this.criteriaCounting = this.productCriteriaDataManagementService
            .getCountingsByCriteriaId(this.criteriaId)
            .map((x) => x);
        this.criteriaFulFilled = this.criteriaCounting.map((x) =>
            this.isMatched(x)
        );
        this.optionScenarioForCriteria =
            this.productCriteriaDataManagementService.getOptionScenarioByCriteriaId(
                this.criteriaId
            );
    }

    /** This method will return particular option by id */
    getOptionById(optionId: string): IOption {
        return this.options.filter((x) => x.optionId === optionId)[0];
    }

    /** This method should be triggered when values have changed and potentials need to be updated. */
    handleChange(): void {
        /**Loop through all options and update the counting array and previous state if there are changes. */
        const selectedOptions = findAllActualSelectedOption(
            this.options,
            this.mappingOfOptionIdsBySingleOptionId
        );
        this.options.forEach((option) => {
            const previousState = this.previousOptionsState.get(
                option.optionId
            );
            const isSelected = selectedOptions.includes(option.optionId);
            if (previousState !== isSelected) {
                this.criteriaCounting =
                    this.calculateNewCountingArrayWhenCriteriaOptionChanged(
                        option,
                        isSelected,
                        this.criteriaCounting
                    );
                this.previousOptionsState.set(option.optionId, isSelected);
            }
        });
        this.criteriaFulFilled = this.criteriaCounting.map((x) =>
            this.isMatched(x)
        );
        this.productCriteriaDataManagementService.updateProductCriteriaDataStore();
    }

    /** Check particular request/booking matched criteria from stored array*/
    isCriteriaMatchedByIndex(index: number): boolean {
        return this.criteriaFulFilled[index];
    }

    private calculateNewCountingArrayWhenCriteriaOptionChanged(
        option: IOption,
        state: boolean,
        currentCountings: number[]
    ): number[] {
        const oldScenario = convertBinaryStringToArray(
            this.optionScenarioForCriteria[option.optionId][
                convertToIsCheckedType(!state)
            ]
        );
        const currentScenario = convertBinaryStringToArray(
            this.optionScenarioForCriteria[option.optionId][
                convertToIsCheckedType(state)
            ]
        );
        const changedValue = subtractArrays(currentScenario, oldScenario);
        return sumUpArrays([currentCountings, changedValue]);
    }

    /** Check particular request/booking matched criteria*/
    private isMatched(numberOfMatched: number): boolean {
        //if it is options input type and logic is 'and' match with option number
        //if it is options input type and logic is 'or' match with greater than 0
        if (this.operatorRule === OperatorRule.Or) {
            return numberOfMatched > 0;
        } else {
            return numberOfMatched === this.options.length;
        }
    }
}
