import { useState } from "react";
import { EffectiveLeadNettoPriceAdjustmentState, LeadType } from "../types/enums";
import BookingSinglePrice from "./BookingSinglePrice";
import { calculateBookingPricePotential } from "../utils/bookingPriceOverview";
import { IBookingPotentialMatchingInformation } from "../types/interfaces";
import BookingChangesHint from "./BookingChangesHint";

interface Props {
    leadPrice1: number;
    leadPrice2: number;
    leadPrice3: number;
    leadType: LeadType;
    cancellationQuote: number;
    effectiveLeadNettoPriceAdjustmentState : EffectiveLeadNettoPriceAdjustmentState
    bookingId: string;
    bookingPricePotentialInfos: IBookingPotentialMatchingInformation[];
    getPotentialNumberOnBookingPriceValueChange: (potential: number) => void;
}

type BookingPriceOverviewState = {
    leadPrice1: number;
    leadPrice2: number;
    leadPrice3: number;
    leadType: LeadType | null;
};
/**
 * BookingPriceOverview is a React component designed to facilitate the management and visualization
 * of lead prices within a booking system. This component allows users to adjust and review the impact
 * of different lead prices on their market share through a series of interactive elements including sliders
 * and checkboxes for each lead type.
 *
 * The component integrates complex business logic to maintain coherent lead price tiers and provides feedback
 * on the potential outcomes of changes made by the user, thus supporting informed decision-making.
 *
 */
function BookingPriceOverview({
    leadPrice1,
    leadPrice2,
    leadPrice3,
    leadType,
    cancellationQuote,
    effectiveLeadNettoPriceAdjustmentState,
    bookingId,
    bookingPricePotentialInfos,
    getPotentialNumberOnBookingPriceValueChange
}: Readonly<Props>) {
    const [bookingPriceOverviewState, setBookingPriceOverviewState] =
        useState<BookingPriceOverviewState>({
            leadPrice1: leadPrice1,
            leadPrice2: leadPrice2,
            leadPrice3: leadPrice3,
            leadType: leadType
        });

    function getStateOfCheckboxCheck(leadType: LeadType): boolean {
        return (
            bookingPriceOverviewState.leadType != null &&
            bookingPriceOverviewState.leadType >= leadType
        );
    }

    function getStateOfCheckboxDisability(leadType: LeadType): boolean {
        return (
            bookingPriceOverviewState.leadType != null &&
            bookingPriceOverviewState.leadType > leadType
        );
    }

    function budgetTypeOnChange(checked: boolean, leadType: LeadType) {
        let type: LeadType | null = null;
        if (!checked) {
            type = leadType - 1 === 0 ? null : leadType - 1;
        } else {
            type = leadType;
        }
        getPotentialNumberOnBookingPriceValueChange(
            calculateBookingPricePotential(
                bookingPricePotentialInfos,
                type,
                bookingPriceOverviewState.leadPrice1,
                bookingPriceOverviewState.leadPrice2,
                bookingPriceOverviewState.leadPrice3,
                cancellationQuote,
                effectiveLeadNettoPriceAdjustmentState
            )
        );
        setBookingPriceOverviewState({
            ...bookingPriceOverviewState,
            leadType: type
        });
    }

    function budgetValueOnChange(newValue: number, leadType: LeadType) {
        let price1 = bookingPriceOverviewState.leadPrice1;
        let price2 = bookingPriceOverviewState.leadPrice2;
        let price3 = bookingPriceOverviewState.leadPrice3;
        if (leadType === LeadType.TrippleLeads) {
            price3 = newValue;
            if (price3 > price2) price2 = newValue;
            if (price2 > price1) price1 = newValue;
        } else if (leadType === LeadType.DualLeads) {
            price2 = newValue;
            if (price2 > price1) price1 = price2;
            if (price2 < price3) price3 = price2;
        } else if (leadType === LeadType.SingleLeads) {
            price1 = newValue;
            if (price1 < price2) price2 = price1;
            if (price1 < price3) price3 = price1;
        }
        getPotentialNumberOnBookingPriceValueChange(
            calculateBookingPricePotential(
                bookingPricePotentialInfos,
                bookingPriceOverviewState.leadType,
                price1,
                price2,
                price3,
                cancellationQuote,
                effectiveLeadNettoPriceAdjustmentState
            )
        );
        setBookingPriceOverviewState({
            ...bookingPriceOverviewState,
            leadPrice1: price1,
            leadPrice2: price2,
            leadPrice3: price3
        });
    }
    return (
        <>
            <h2>
                Prüfen Sie den Einfluss Ihrer Leadpreise auf Ihren Marktanteil
            </h2>
            <BookingChangesHint />
            <BookingSinglePrice
                key={`3er-${bookingId}`}
                bookingId={bookingId}
                sliderMinValue={bookingPriceOverviewState.leadPrice3}
                checkBoxChecked={getStateOfCheckboxCheck(LeadType.TrippleLeads)}
                priceLabel="3er Leadpreis"
                sliderValue={bookingPriceOverviewState.leadPrice3}
                checkBoxDisabled={getStateOfCheckboxDisability(
                    LeadType.TrippleLeads
                )}
                leadType={LeadType.TrippleLeads}
                onCheckboxChanged={budgetTypeOnChange}
                onSliderValueChanged={budgetValueOnChange}
            />
            <BookingSinglePrice
                key={`2er-${bookingId}`}
                bookingId={bookingId}
                sliderMinValue={bookingPriceOverviewState.leadPrice3}
                checkBoxChecked={getStateOfCheckboxCheck(LeadType.DualLeads)}
                priceLabel="2er Leadpreis"
                sliderValue={bookingPriceOverviewState.leadPrice2}
                leadType={LeadType.DualLeads}
                checkBoxDisabled={getStateOfCheckboxDisability(
                    LeadType.DualLeads
                )}
                onCheckboxChanged={budgetTypeOnChange}
                onSliderValueChanged={budgetValueOnChange}
            />
            <BookingSinglePrice
                key={`1er-${bookingId}`}
                bookingId={bookingId}
                sliderMinValue={bookingPriceOverviewState.leadPrice3}
                checkBoxChecked={getStateOfCheckboxCheck(LeadType.SingleLeads)}
                priceLabel="1er Leadpreis"
                leadType={LeadType.SingleLeads}
                sliderValue={bookingPriceOverviewState.leadPrice1}
                checkBoxDisabled={getStateOfCheckboxDisability(
                    LeadType.SingleLeads
                )}
                onCheckboxChanged={budgetTypeOnChange}
                onSliderValueChanged={budgetValueOnChange}
            />
        </>
    );
}

export default BookingPriceOverview;
