import {
    Query,
    QueryCache,
    QueryClient,
    QueryKey
} from "@tanstack/react-query";

export type ReactQueryErrorHandler = (
    error: unknown,
    query: Query<unknown, unknown, unknown, QueryKey>
) => void;

export const createQueryClient = (onError: ReactQueryErrorHandler) => {
    return new QueryClient({
        queryCache: new QueryCache({
            onError: onError
        }),
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false,
                cacheTime: 0
            }
        }
    });
};
