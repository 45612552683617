import { useParams } from "react-router-dom";
import "../../../assets/styles/waitingListBookingChangeRequest/ChangeRequest.scss";
import { useState } from "react";
import { toast } from "react-toastify";
import ZipCodeConfirmationModal from "./ZipCodeConfirmationModal";
import ZipCodeConfirmationMessage from "./ZipCodeConfirmationMessage";
import ZipCodeInformationForm, {
    IZipCodeInformationFormData
} from "./ZipCodeInformationForm";
import { usePostSendWaitingListBookingZipCode } from "../services/waitingListBookingChangeRequestService";

function ZipCodeInformationOverview() {
    const { supplierId } = useParams() as { supplierId: string };
    const [confirmationModel, setConfirmationModel] = useState<boolean>(false);
    const [isInformationAlreadySent, setIsInformationAlreadySent] =
        useState<boolean>(false);
    const [zipCodeDetails, setZipCodeDetails] = useState({
        zipCode: 0,
        radius: 0
    });

    const errorMessage =
        "Aktualisierung ist aktuell nicht möglich. Bitte versuche Sie es später erneut.";

    const { mutate: sendZipCodeInfo } = usePostSendWaitingListBookingZipCode(
        supplierId,
        {
            onSuccess: () => {
                submitOnSuccess();
            },
            onError: () => {
                toast.error(errorMessage);
            }
        }
    );

    function zipCodeUpdateOnSubmit(formData: IZipCodeInformationFormData) {
        const numericZipCode = Number(formData.zipCode);
        const numericRadius = Number(formData.radius);

        // Validate the conversion results and check if they are numbers
        if (isNaN(numericZipCode) || isNaN(numericRadius)) {
            throw new Error("Invalid zip code or radius");
        }

        const data = {
            zipCode: numericZipCode,
            radius: numericRadius
        };

        setZipCodeDetails(data);
        sendZipCodeInfo(data);
    }

    const submitOnSuccess = () => {
        setIsInformationAlreadySent(true);
        setConfirmationModel(true);
    };

    return (
        <div className="waiting-list-booking-change-request-container">
            <h1 className="text-blue mtb-25">Zielgebiet</h1>
            <div className="flex-wrap align-center">
                <h3 className="highlight-green mtb-0">
                    Ist Ihr Zielgebiet korrekt hinterlegt?
                </h3>
            </div>
            <p className="text-black">
                Halten Sie Ihr Zielgebiet auf dem aktuellen Stand, um nur{" "}
                <strong>
                    Anfragen aus Ihrem individuell festgelegten Umkreis
                </strong>{" "}
                zu erhalten.
            </p>
            <div className="change-request-form-container mtb-50">
                <p className="change-request-form-heading">Zielgebiet</p>
                {isInformationAlreadySent ? (
                    <ZipCodeConfirmationMessage
                        zipCode={zipCodeDetails.zipCode}
                        radius={zipCodeDetails.radius}
                    />
                ) : (
                    <ZipCodeInformationForm onSubmit={zipCodeUpdateOnSubmit} />
                )}
            </div>
            <ZipCodeConfirmationModal
                popUpOpen={confirmationModel}
                setPopUpOpen={setConfirmationModel}
            />
        </div>
    );
}

export default ZipCodeInformationOverview;
