import { useEffect } from "react";
import "../../assets/styles/components/MobileNavIcon.scss";
import { useLocation } from "react-router-dom";
import { useNavigationDrawer } from "../../context";

interface MobileNavIconProps {
    className?: string;
}

/**
 * MobileNavIcon is a React component that renders a mobile navigation icon.
 * This icon acts as a button to toggle the visibility of a navigation drawer in a mobile interface.
 * The component leverages React hooks for state management and side effects to control the
 * navigation drawer's state based on user interactions and URL changes.
 *
 */
function MobileNavIcon({ className }: Readonly<MobileNavIconProps>) {
    const { isNavigationDrawerOpen, toggleDrawer } = useNavigationDrawer();

    const handleClick = () => {
        toggleDrawer(!isNavigationDrawerOpen);
    };

    const location = useLocation();

    useEffect(() => {
        toggleDrawer(false);
    }, [location]); // Only re-run if url changes

    const className1 = isNavigationDrawerOpen ? "arrow-rotate" : "";
    const className2 = isNavigationDrawerOpen ? "arrow-hide" : "";
    const className3 = isNavigationDrawerOpen ? "arrow-rotate-back" : "";

    return (
        <>
            <div className={`wrap ${className}`} onClick={handleClick}>
                <div className="burger">
                    <div className="strip burger-strip-2">
                        <div className={className1}></div>
                        <div className={className2}></div>
                        <div className={className3}></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileNavIcon;
