import {
    UseMutationOptions,
    useMutation,
    useQuery
} from "@tanstack/react-query";
import { get, post } from "../../../lib";
import {
    ILeadReceiptsInfo,
    ILeadReceiptRequestDto,
    IZipCodeRequestDto
} from "./dtos";
import {
    getGetWaitingListLeadReceiptsInfo,
    postSendWaitingListBookingLeadReceiptInfo,
    postSendWaitingListBookingZipCodeInfo
} from "../../../constants/reactQueryKey";
import ApiError from "../../../lib/ApiError";
export const useGetWaitingListLeadReceiptsInfo = (
    supplierId: string,
    options = {}
) => {
    return useQuery(
        [getGetWaitingListLeadReceiptsInfo, supplierId],
        async () => {
            return await get<ILeadReceiptsInfo>(
                `/WaitingListLeads/${supplierId}/receiptsInfo`
            );
        },
        {
            ...options
        }
    );
};

export const usePostSendWaitingListBookingLeadReceipt = (
    supplierId: string,
    options: UseMutationOptions<
        void,
        ApiError,
        ILeadReceiptRequestDto,
        unknown
    > = {}
) => {
    return useMutation<void, ApiError, ILeadReceiptRequestDto>({
        mutationKey: [postSendWaitingListBookingLeadReceiptInfo, supplierId],
        mutationFn: (data: ILeadReceiptRequestDto) =>
            post(`/WaitingListLeads/${supplierId}/receiptInfo`, data),
        ...options
    });
};

export const usePostSendWaitingListBookingZipCode = (
    supplierId: string,
    options: UseMutationOptions<
        void,
        ApiError,
        IZipCodeRequestDto,
        unknown
    > = {}
) => {
    return useMutation<void, ApiError, IZipCodeRequestDto>({
        mutationKey: [postSendWaitingListBookingZipCodeInfo, supplierId],
        mutationFn: (data: IZipCodeRequestDto) =>
            post(`/WaitingListLeads/${supplierId}/zipCodeInfo`, data),
        ...options
    });
};
