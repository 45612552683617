import { BookingPotentialCategory } from "../../../../types/enums";
import { EffectiveLeadNettoPriceAdjustmentStateExtensions } from "../../extensions/EffectiveLeadNettoPriceAdjustmentStateExtensions";
import { EffectiveLeadNettoPriceAdjustmentState, LeadType } from "../../types/enums";
import { IBookingPotentialMatchingInformation } from "../../types/interfaces";

export function calculateBookingPricePotential(
    bookingPotentials: IBookingPotentialMatchingInformation[],
    leadType: LeadType | null,
    leadPrice1: number,
    leadPrice2: number,
    leadPrice3: number,
    cancellationQuote: number,
    effectiveLeadNettoPriceAdjustmentState : EffectiveLeadNettoPriceAdjustmentState
): number {
    // If leadPrice1 is not positive or if leadType is not provided, return zero potential.
    if (leadPrice1 <= 0 || leadType == null) return 0;

    /**
     * Helper function to calculate the maximum percentage for a given BookingPotentialCategory.
     * It filters the potentials based on the category and lead price after considering the cancellation quote,
     * then sorts them in descending order based on value, and returns the percentage of the highest potential.
     */
    const getMaxPercentageForCategory = (
        category: BookingPotentialCategory,
        leadPrice: number
    ) => {
        const potentials = bookingPotentials
            .filter(
                (bp) =>
                    bp.category === category &&
                    parseFloat(bp.value) <= leadPrice * (1 - cancellationQuote) * EffectiveLeadNettoPriceAdjustmentStateExtensions.toFactor(effectiveLeadNettoPriceAdjustmentState)
            )
            .sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

        return potentials.length > 0 ? potentials[0].percentage : 0;
    };

    // For booking with lead type (1er / 2er / 3er)
    // Calculate potential percentages for each lead price category.
    const percentage1 = getMaxPercentageForCategory(
        BookingPotentialCategory.LeadPrice1,
        leadPrice1
    );

    // For booking with lead type (2er / 3er)
    const percentage2 =
        leadType !== LeadType.SingleLeads
            ? getMaxPercentageForCategory(
                  BookingPotentialCategory.LeadPrice2,
                  leadPrice2
              )
            : 0;

    // For booking with lead type (3er)
    const percentage3 =
        leadType === LeadType.TrippleLeads
            ? getMaxPercentageForCategory(
                  BookingPotentialCategory.LeadPrice3,
                  leadPrice3
              )
            : 0;

    // Return the highest potential percentage among the three categories.
    return Math.max(percentage1, percentage2, percentage3);
}
