import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BookingDetailsContainerTab } from "../../types/enums";

/**
 * useBookingTabRoute is a custom React hook designed to manage navigation and state synchronization
 * for tab components within a booking interface based on URL hash changes. This hook facilitates
 * deep linking to specific tabs and ensures that the tab selection remains in sync with the URL's hash segment.
 *
 * Returns:
 *  - currentBookingTab: A string representing the currently active tab, derived from the URL's hash.
 *  - tabHandleChange: A function that updates the URL's hash when a new tab is selected, triggering navigation.
 *
 */
function useBookingTabRoute() {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentBookingTab, setCurrentBookingTab] = useState<string>(
        getCurrentBookingTab(location.hash)
    );

    useEffect(() => {
        setCurrentBookingTab(getCurrentBookingTab(location.hash));
    }, [location.hash]);

    function getCurrentBookingTab(locationHash: string): string {
        return (
            String(locationHash.replace("#", "")) ||
            BookingDetailsContainerTab.CRITERIA
        );
    }

    function tabHandleChange(
        _: React.SyntheticEvent,
        newTabValue: BookingDetailsContainerTab
    ) {
        navigate(`#${newTabValue}`);
    }

    return { currentBookingTab, tabHandleChange };
}

export default useBookingTabRoute;
