import { Navigate, Route } from "react-router-dom";
import { ErrorPage } from "../../components/ui";

const ErrorRoutes = (
    <>
        <Route path="404" element={<ErrorPage statusCode={404} />}></Route>
        <Route path="500" element={<ErrorPage statusCode={500} />}></Route>
        <Route path="*" element={<Navigate to="404" replace />} />
    </>
);

export default ErrorRoutes;
