import { ILeadReceiptsContactInformationFormData } from "../../components/LeadReceiptsContactInformationForm";
import { ILeadReceipt, ILeadReceiptRequestDto } from "../../services/dtos";

export function convertWaitingListLeadReceiptToRequest(
    waitingListLeadReceiptOldData: ILeadReceipt | null,
    waitingListReceiptNewData: ILeadReceiptsContactInformationFormData
): ILeadReceiptRequestDto {
    return {
        oldFirstName: waitingListLeadReceiptOldData?.firstName ?? "",
        oldLastName: waitingListLeadReceiptOldData?.surName ?? "",
        oldEmail: waitingListLeadReceiptOldData?.email ?? "",
        oldMobileNumber:
            waitingListLeadReceiptOldData?.mobileNumber.number ?? "",
        oldFaxNumber: waitingListLeadReceiptOldData?.faxNumber.number ?? "",
        newName: waitingListReceiptNewData.name.trim(),
        newEmail: waitingListReceiptNewData.emailAddress,
        newMobileNumber: waitingListReceiptNewData.mobileNumber,
        newFaxNumber: waitingListReceiptNewData.faxNumber,
        isMobileNumberSelected: waitingListReceiptNewData.isMobileNumberActive,
        isFaxNumberSelected: waitingListReceiptNewData.isFaxNumberActive
    };
}
export function convertWaitingListLeadReceiptToFormData(
    contact: ILeadReceipt | undefined
): ILeadReceiptsContactInformationFormData | undefined {
    if (contact === undefined) return undefined;
    return {
        name:
            contact.firstName || contact.surName
                ? (contact.firstName ?? "") + " " + (contact.surName ?? "")
                : "",
        emailAddress: contact.email ?? "",
        mobileNumber: contact.mobileNumber.number ?? "",
        isMobileNumberActive: contact.mobileNumber.isSelected,
        faxNumber: contact.faxNumber.number ?? "",
        isFaxNumberActive: contact.faxNumber.isSelected
    };
}
