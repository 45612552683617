import { IBookingAreaInformation } from "../../components/BookingZipCodeOverview";
import { IBookingPotentialMatchingInformation } from "../../types/interfaces";

export function getUniqueZipCodesFromPostalAreaArray(
    companyAreas: IBookingAreaInformation[]
): [Set<string>, number] {
    const uniqueZipCodes = new Set(
        companyAreas
            .filter((info) => info.isSelected)
            .flatMap((info) => info.zipCodeList.map((zip) => zip.zipCode))
    );

    return [uniqueZipCodes, uniqueZipCodes.size];
}

export function calculateBookingZipCodePotential(
    bookingPotentials: IBookingPotentialMatchingInformation[],
    zipCodeList: string[],
    isNationwide: boolean = false
): number {
    if (isNationwide) return 1;
    if (zipCodeList.length === 0) return 0;
    return bookingPotentials
        .filter((bp) => zipCodeList.includes(bp.value))
        .reduce((acc, x) => acc + x.percentage, 0);
}
