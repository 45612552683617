import {
    EmailRegex,
    MaxRadius,
    MinRadius,
    ZipRegex
} from "../../../../constants";
import { isValidPhoneNumber } from "../../../../utils";

interface ValidationRulesProps {
    isMobileNumberActive?: boolean;
    isFaxNumberActive?: boolean;
}

export const useFormValidationRules = ({
    isMobileNumberActive,
    isFaxNumberActive
}: Readonly<ValidationRulesProps>) => {
    const emailAddressRules = {
        required: "E-Mail-Adresse ist erforderlich",
        pattern: {
            value: EmailRegex,
            message: "Keine gültige E-Mail-Adresse"
        }
    };

    const mobileNumberRules = {
        required: isMobileNumberActive
            ? { value: true, message: "Telefonnummer ist erforderlich" }
            : false,
        validate: (value: string) =>
            value.trim() === "" ||
            isValidPhoneNumber(value) ||
            "Keine gültige Telefonnummer"
    };

    const faxNumberRules = {
        required: isFaxNumberActive
            ? { value: true, message: "Faxnummer ist erforderlich" }
            : false,
        validate: (value: string) =>
            value.trim() === "" ||
            isValidPhoneNumber(value) ||
            "Keine gültige Faxnummer"
    };

    const zipCodeRules = {
        required: "PLZ ist erforderlich",
        pattern: {
            value: ZipRegex,
            message: "Keine gültige PLZ"
        }
    };

    const radiusRules = {
        required: "Umkreis ist erforderlich",
        validate: (value: string) => {
            const numericValue = Number(value);
            // Check if the conversion result is a number and within the specified range
            if (
                !isNaN(numericValue) &&
                numericValue >= MinRadius &&
                numericValue <= MaxRadius
            ) {
                return true; // The value is valid
            }
            return "Radius ist nicht gültig";
        }
    };

    return {
        emailAddressRules,
        mobileNumberRules,
        faxNumberRules,
        zipCodeRules,
        radiusRules
    };
};
