/**
 * Sums up multiple arrays element-wise.
 * @function sumUpArrays
 * @param {readonly (readonly number[])[]} numberArrays - Arrays to be summed up.
 * @returns {number[]} An array containing the element-wise sum of input arrays.
 */
export function sumUpArrays(
    numberArrays: readonly (readonly number[])[]
): number[] {
    const result = new Array(numberArrays[0].length).fill(0);
    result.forEach((_, index) => {
        numberArrays.forEach((numberArray) => {
            result[index] += numberArray[index];
        });
    });
    return result;
}
