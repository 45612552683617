import { Modal } from "@mui/material";
import "../../../assets/styles/waitingListBookingChangeRequest/ConfirmationModal.scss";

interface IZipCodeConfirmationModalProps {
    popUpOpen: boolean;
    setPopUpOpen: (open: boolean) => void;
}

function ZipCodeConfirmationModal({
    popUpOpen,
    setPopUpOpen
}: Readonly<IZipCodeConfirmationModalProps>) {
    return (
        <Modal
            className="waiting-list-booking-change-request-modal"
            open={popUpOpen}
            onClose={() => {
                setPopUpOpen(false);
            }}
            aria-labelledby="waiting-list-booking-lead-receipt"
            aria-describedby="waiting-list-booking-lead-receipt"
        >
            <div className="waiting-list-booking-change-request-body">
                <div
                    className="icon icon-close"
                    role="button"
                    onClick={() => setPopUpOpen(false)}
                ></div>
                <h2 className="highlight-blue">
                    Vielen Dank für die Aktualisierung Ihres Zielgebiets
                </h2>
                <h3 className="highlight-green">
                    Ihre Änderungen werden zeitnah übernommen
                </h3>
                <p className="text-black mt-20">
                    <strong>So geht es jetzt weiter:</strong>
                </p>
                <ol className="list">
                    <li>Wir haben Ihr aktualisiertes Zielgebiet erhalten.</li>
                    <li>
                        Ihre Änderungen werden in der Regel innerhalb des{" "}
                        <strong> nächsten Werktages übernommen.</strong>
                    </li>
                    <li>
                        Sobald Ihre Änderungen übernommen wurden, erhalten Sie
                        die
                        <strong>
                            {" "}
                            Anfragen aus Ihrem festgelegten Zielgebiet.
                        </strong>
                    </li>
                </ol>
                <div className="mb-20">
                    <i>
                        <u>Hinweis</u>: Bis zur finalen Speicherung erhalten Sie die Anfragen aus dem bisherigen Zielgebiet.
                    </i>
                </div>
            </div>
        </Modal>
    );
}
export default ZipCodeConfirmationModal;
