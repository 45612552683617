import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { getTextByBudgetUnit } from "../utils/bookingStickyHeader/mapper";
import { BudgetUnit } from "../types/enums";
import "../../../assets/styles/booking/BookingStickyHeader.scss";

interface IBudget {
    count: number;
    unit: BudgetUnit;
    totalCount: any;
}

interface Props {
    isSticky: boolean;
    totalNumberOfRequest: number | undefined;
    matchNumberOfRequest: number | undefined;
    budgetInfo: IBudget | null | undefined;
    cancellationQuote: number | undefined;
}

/**
 * BookingStickyHeader is a React component that serves as a dynamic header within a booking system,
 * providing key metrics and actionable insights related to a booking scenario. It's designed to stick
 * to the top of the viewport when scrolling to keep vital information visible. The component displays
 * data about budget, cancellation rates, and market share, and dynamically updates a visual representation
 * of the user's market presence compared to potential market presence based on current data.
 *
 */
function BookingStickyHeader({
    isSticky,
    totalNumberOfRequest,
    matchNumberOfRequest,
    cancellationQuote,
    budgetInfo
}: Readonly<Props>) {
    const [percentage, setPercentage] = useState(0);

    const stickyClass = isSticky ? "sticky" : "";

    useEffect(() => {
        const value =
            totalNumberOfRequest !== undefined &&
            matchNumberOfRequest !== undefined
                ? Math.round(
                      (matchNumberOfRequest / totalNumberOfRequest) * 100
                  )
                : 0;
        setPercentage(value);
    }, [totalNumberOfRequest, matchNumberOfRequest]);

    function renderBudgetInfo() {
        if (budgetInfo) {
            return budgetInfo.count != null
                ? `${budgetInfo.count} Anfragen`
                : "";
        } else {
            return "Kein Limit";
        }
    }

    return (
        <div className={`sticky-header-container ${stickyClass}`}>
            <div className="sticky-header">
                <div className="sticky-header-column">
                    <h2>
                        Übersicht
                        <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={10000}
                            arrow
                            title={
                                <span>
                                    Ihr Budget und Ihre Reklamationsquote nehmen
                                    Einfluss auf Ihren Marktanteil.{" "}
                                    <strong>Unser Tipp:</strong> Steigern Sie
                                    Ihr Budget oder reduzieren Sie Ihre
                                    Reklamationsquote, um einen höheren
                                    Marktanteil zu erzielen.
                                </span>
                            }
                        >
                            <div className="information-icon"></div>
                        </Tooltip>
                    </h2>
                    {(budgetInfo == null || budgetInfo.count !== null) && (
                        <div className="sticky-header-row">
                            <span className="highlight-blue">Ihr Budget: </span>
                            {renderBudgetInfo()}
                            {budgetInfo?.unit != null && (
                                <span className="highlight-green">
                                    {` / ${getTextByBudgetUnit(
                                        budgetInfo.unit
                                    )}`}
                                </span>
                            )}
                        </div>
                    )}

                    {budgetInfo?.totalCount && (
                        <div className="sticky-header-row">
                            <span className="highlight-blue">
                                Ihr Gesamtlimit:{" "}
                            </span>
                            {budgetInfo.totalCount}
                        </div>
                    )}
                    <div className="sticky-header-row">
                        <span className="highlight-blue">
                            Ihre Reklamationsquote:{" "}
                        </span>
                        {cancellationQuote !== undefined &&
                            (cancellationQuote * 100).toFixed(2)}{" "}
                        %
                    </div>
                </div>
                <div className="sticky-header-column">
                    <h2>
                        Ihr aktueller Marktanteil
                        <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={10000}
                            arrow
                            title={
                                <span>
                                    Der Marktanteil zeigt die Gesamtheit der
                                    potenziellen Anfragen im Vergleich zu Ihren
                                    tatsächlichen Anfragen.{" "}
                                    <strong>Unser Tipp:</strong> Verändern Sie
                                    Ihre Vorqualifizierung, um einen höheren
                                    Marktanteil zu erzielen.
                                </span>
                            }
                        >
                            <div className="information-icon"></div>
                        </Tooltip>
                    </h2>
                    <div className="grid-two-columns">
                        <div className="sticky-header-row">
                            <span className="highlight-blue">
                                Alle Anfragen:{" "}
                            </span>
                        </div>
                        <div className="progress-bar-container">
                            {totalNumberOfRequest && (
                                <>
                                    <div className="progress-bar progress-bar-green progress-bar-100"></div>
                                    {totalNumberOfRequest}
                                </>
                            )}
                        </div>
                        <div className="sticky-header-row">
                            <span className="highlight-blue">
                                Ihre Anfragen:{" "}
                            </span>
                        </div>
                        <div className="progress-bar-container">
                            {matchNumberOfRequest !== undefined && (
                                <>
                                    <div
                                        className={`progress-bar progress-bar-blue progress-bar-${percentage}`}
                                    ></div>
                                    {matchNumberOfRequest}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookingStickyHeader;
