// The backend uses "True" | "False" with that exact spelling for isChecked.
type isChecked = "True" | "False";

export interface OptionResults {
    [optionId: string]: {
        [key in isChecked]: string;
    };
}

export function convertToIsCheckedType(val: boolean): isChecked {
    return val ? "True" : "False";
}
