export function subtractArrays(
    first: readonly number[],
    second: readonly number[]
) {
    if (first.length !== second.length) {
        throw new Error("Arrays must have the same length for subtraction.");
    }

    return first.map((element, index) => element - second[index]);
}
