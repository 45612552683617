import { Modal } from "@mui/material";
import "../../../assets/styles/waitingListBookingChangeRequest/ConfirmationModal.scss";
import { Link, useParams } from "react-router-dom";

interface ILeadReceiptConfirmationModalProps {
    popUpOpen: boolean;
    setPopUpOpen: (open: boolean) => void;
}

function LeadReceiptConfirmationModal({
    popUpOpen,
    setPopUpOpen
}: Readonly<ILeadReceiptConfirmationModalProps>) {
    const { supplierId } = useParams() as { supplierId: string };
    const { secureId } = useParams() as { secureId: string };
    return (
        <Modal
            className="waiting-list-booking-change-request-modal"
            open={popUpOpen}
            onClose={() => {
                setPopUpOpen(false);
            }}
            aria-labelledby="waiting-list-booking-lead-receipt"
            aria-describedby="waiting-list-booking-lead-receipt"
        >
            <div className="waiting-list-booking-change-request-body">
                <div
                    className="icon icon-close"
                    role="button"
                    onClick={() => setPopUpOpen(false)}
                ></div>
                <h2 className="highlight-blue">
                    Vielen Dank für die Aktualisierung Ihrer Kontaktdaten
                </h2>
                <h3 className="highlight-green">
                    Ihre Änderungen werden zeitnah übernommen
                </h3>
                <p className="text-black mt-20">
                    <strong>So geht es jetzt weiter:</strong>
                </p>
                <ol className="list">
                    <li>
                        Wir haben Ihre aktualisierten Kontaktdaten erhalten.
                    </li>
                    <li>
                        Ihre Änderungen werden in der Regel innerhalb des{" "}
                        <strong> nächsten Werktages übernommen.</strong>
                    </li>
                    <li>
                        Sobald Ihre Änderungen übernommen wurden, erhalten Sie
                        die
                        <strong> Anfragen an Ihre neuen Kontaktdaten.</strong>
                    </li>
                </ol>
                <div className="mb-20">
                    <i>
                        <u>Hinweis</u> : In dem Reiter “Kontaktdaten” sind die Anpassungen erst nach finaler Speicherung für Sie ersichtlich. Bis zur finalen Speicherung erhalten Sie die Anfragen an Ihre vorherigen Kontaktdaten.
                    </i>
                </div>
                <div className="mb-10 tip-class">
                    <h4 className="highlight-blue">Unser Tipp</h4>
                    Hinterlegen Sie jetzt auch Ihr{" "}
                    <strong>individuell festgelegtes Zielgebiet</strong> und
                    erhalten Sie nur Anfragen aus Ihrem Wunsch-Umkreis.{" "}
                    <Link className="text-blue"
                        to={`/${supplierId}/waitinglistrequests/${secureId}/zipcodeform`}
                    >
                        Zielgebiet hinterlegen »
                    </Link>
                </div>
            </div>
        </Modal>
    );
}
export default LeadReceiptConfirmationModal;
