import {
    QueryClient,
    UseMutationOptions,
    useMutation,
    useQuery
} from "@tanstack/react-query";
import { get, patch } from "../../../lib";
import {
    IWaitingListLeadBasicInfo,
    IWaitingListLeadProductCriteriaInfo
} from "./dtos";
import {
    getGetWaitingListLeadBasicInfo,
    getGetWaitingListLeadCriteriaInfo,
    patchAcquireWaitingListLead,
    patchRejectWaitingListLead
} from "../../../constants/reactQueryKey";
import ApiError from "../../../lib/ApiError";

export const useGetWaitingListLeadBasicInfo = (
    secureId: string,
    options = {}
) => {
    return useQuery(
        [getGetWaitingListLeadBasicInfo, secureId],
        async () => {
            return await get<IWaitingListLeadBasicInfo>(
                `/WaitingListLeads/${secureId}/basicInfo`
            );
        },
        {
            ...options
        }
    );
};

export const useGetWaitingListLeadCriteriaInfo = (
    secureId: string,
    options = {}
) => {
    return useQuery(
        [getGetWaitingListLeadCriteriaInfo, secureId],
        async () => {
            return await get<IWaitingListLeadProductCriteriaInfo>(
                `/WaitingListLeads/${secureId}/criteriaInfo`
            );
        },
        {
            ...options
        }
    );
};

export const usePatchMakeWaitingListLeadAcquired = (
    secureId: string,
    options: UseMutationOptions<void, ApiError, void, unknown> = {}
) => {
    return useMutation<void, ApiError, void>({
        mutationKey: [patchAcquireWaitingListLead, secureId],
        mutationFn: () => patch<void>(`/WaitingListLeads/${secureId}/acquire`),
        ...options
    });
};

export const usePatchMakeWaitingListLeadRejected = (
    secureId: string,
    options: UseMutationOptions<void, ApiError, void, unknown> = {}
) => {
    return useMutation<void, ApiError, void>({
        mutationKey: [patchRejectWaitingListLead, secureId],
        mutationFn: () => patch<void>(`/WaitingListLeads/${secureId}/reject`),
        ...options
    });
};

export const invalidateWaitingListLeadQueries = async (
    queryClient: QueryClient
) => {
    queryClient.invalidateQueries([getGetWaitingListLeadBasicInfo]);
    queryClient.invalidateQueries([getGetWaitingListLeadCriteriaInfo]);
};
