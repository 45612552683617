import { LoadingSpinner } from "../components/ui";
import { QueryClientProvider } from "@tanstack/react-query";
import { createQueryClient } from "../lib";
import useErrorHandler from "../hooks/useErrorHandler";
import { ToastContainer } from "react-toastify";
import { NavigationDrawerProvider } from "../context";

interface Props {
    children: React.ReactNode;
}
function AppProvider({ children }: Props) {
    //currrent error handler works for specific api
    // modify the array for particular api
    const { customReactQueryErrorHandler } = useErrorHandler();
    const queryClient = createQueryClient(customReactQueryErrorHandler);
    return (
        <NavigationDrawerProvider>
            <QueryClientProvider client={queryClient}>
                <ToastContainer />
                <LoadingSpinner />
                {children}
            </QueryClientProvider>
        </NavigationDrawerProvider>
    );
}

export default AppProvider;
