import { IProductCriteriaDataManagementService } from "../service/productCriteriaService";
import { SliderResults } from "./types/sliderResults";

interface ISliderConfigurationPresentation {
    step: number;
    hasRange: boolean;
    startFixed: boolean;
    endFixed: boolean;
    rangeStart: number;
    rangeEnd: number;
    selectedValue: string;
    defaultValue: string;
    unit: string;
    textConverterType: number;
    countryIso2: string;
}

/**
 * @interface ISliderConfiguration
 * Represents the configuration settings for a slider input type within a product criteria.
 * @property {number} min - The minimum value of the slider.
 * @property {number} max - The maximum value of the slider.
 * @property {[minOrMax: number]: { [combinations: string]: number}} localPotentials - The potential changes based on the min/max values of the slider.
 * @method handleChange - Triggers an update of potential values when slider values change.
 * @method isCriteriaMatchedByIndex - Check particular request/booking matched criteria
 */
export interface ISliderConfiguration extends ISliderConfigurationPresentation {
    min: number;
    max: number;
    localPotentials: { [minOrMax: number]: { [combinations: string]: number } };

    handleChange(): void;
    isCriteriaMatchedByIndex(index: number): boolean;
}

export class SliderConfiguration implements ISliderConfiguration {
    public step: number;
    public hasRange: boolean;
    public startFixed: boolean;
    public endFixed: boolean;
    public rangeStart: number;
    public rangeEnd: number;
    public selectedValue: string;
    public defaultValue: string;
    public unit: string;
    public textConverterType: number;
    public countryIso2: string;

    public min: number;
    public max: number;
    public localPotentials: {
        [minOrMax: number]: { [combinationRange: string]: number };
    } = {};

    /** Store the information of criteria count */
    private criteriaCounting: number[] = [];
    /** Store the information of criteria fulfilled */
    private criteriaFulFilled: boolean[] = [];
    private sliderScenarioForCriteria: Readonly<SliderResults>;

    constructor(
        objFromJson: ISliderConfiguration,
        private criteriaId: string,
        /** Reference to the product criteria service to update potential values after changes. */
        private productCriteriaDataManagementService: IProductCriteriaDataManagementService
    ) {
        this.step = objFromJson.step;
        this.hasRange = objFromJson.hasRange;
        this.startFixed = objFromJson.startFixed;
        this.endFixed = objFromJson.endFixed;
        this.rangeStart = objFromJson.rangeStart;
        this.rangeEnd = objFromJson.rangeEnd;
        this.selectedValue = objFromJson.selectedValue;
        this.defaultValue = objFromJson.defaultValue;
        this.unit = objFromJson.unit;
        this.textConverterType = objFromJson.textConverterType;
        this.countryIso2 = objFromJson.countryIso2;
        if (objFromJson.selectedValue && objFromJson.selectedValue.trim()) {
            const parsedData = this.parseMinMaxFromString(
                objFromJson.selectedValue
            );
            this.min = parsedData.min;
            this.max = parsedData.max;
        } else {
            this.min = objFromJson.min;
            this.max = objFromJson.max;
        }
        this.criteriaCounting = this.productCriteriaDataManagementService
            .getCountingsByCriteriaId(this.criteriaId)
            .map((x) => x);
        this.criteriaFulFilled = this.criteriaCounting.map((x) =>
            this.isMatchedByBackendLogic(x)
        );
        this.sliderScenarioForCriteria =
            this.productCriteriaDataManagementService.getSliderScenarioByCriteriaId(
                this.criteriaId
            );
    }

    handleChange(): void {
        this.criteriaFulFilled = this.criteriaCounting.map((_, index) =>
            this.isMatched(index)
        );
        this.selectedValue = `[${this.min},${this.max}]`;
        this.productCriteriaDataManagementService.updateProductCriteriaDataStore();
    }

    /** Check particular request/booking matched criteria from stored array*/
    isCriteriaMatchedByIndex(index: number): boolean {
        return this.criteriaFulFilled[index];
    }

    /** Return min and max from selected value [100,150] */
    private parseMinMaxFromString(input: string): { min: number; max: number } {
        const match = input.match(/\[([\d.]+)\s*,\s*([\d.]+)\]/);
        let [min, max] = [0, 0];
        if (match) {
            // Convert the matched values to numbers
            [min, max] = match.slice(1).map(Number);
        }
        return { min, max };
    }

    /** Check particular request/booking matched criteria*/
    private isMatchedByBackendLogic(numberOfMatched: number): boolean {
        //if it is slider input type and logic is 2 set by backend
        return numberOfMatched === 2;
    }

    /** Check particular request/booking matched criteria*/
    private isMatched(index: number): boolean {
        const max = this.sliderScenarioForCriteria[0][index];
        const min = this.sliderScenarioForCriteria[1][index];
        return Math.max(min, this.min) <= Math.min(max, this.max);
    }
}
